// React
import { useEffect, useState } from 'react';

// Libraries
import { useHistory } from 'react-router-dom';

// Parts
import InfoHeaderForm from '../../Parts/Forms/InfoHeader/InfoHeaderForm';

// Commons
import { Button } from '@experta/commons-v2.button';
import { Coverages } from '@experta/commons-v2.coverages';
import { InfoHeader } from '@experta/commons-v2.info-header';
import { Plans } from '@experta/commons-v2.plans';
import { RowProductsDown } from '@experta/commons-v2.row-products-down';
import { StepsPayment } from '@experta/commons-v2.steps-payment';

// Style
import './Plans.css';

// Store
import { getCoveragesAsync } from '../../Store/Coverages/Actions';
import { getLocalitiesAsync } from '../../Store/Localities/Actions';
import {
  getPlansAsync,
  postQuoteAsync,
  reQuoteAsync,
} from '../../Store/Plans/Actions';
import { hideSpinner, showSpinner } from '../../Store/Spinner/Actions';
import { saveInitialFormData, savePropertyFormData } from '../../Store/Forms/Slice';
import { selectCoveragesCards } from '../../Store/Coverages/Selectors';
import { selectEnvVariables } from '../../Store/Variables/selectors';
import { selectInitialFormData } from '../../Store/Forms/Selectors';
import { selectLocalitiesCombo } from '../../Store/Localities/Selectors';
import { selectBienAsegurados, selectPlans } from '../../Store/Plans/Selectors';
import { selectToken } from '../../Store/Auth/Selectors';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';

// Utils
import { getAssists, getTextFormatPrice, usePlansUtils } from './utils';

// Assets
import Icon1 from '../../assets/Icons/ICONO_COTIZACION.svg';
import Icon2 from '../../assets/Icons/ICONO_PLANES.svg';
import Icon3 from '../../assets/Icons/ICONO_PAGO.svg';

// Language
import * as lang from './lang.json';

/**
 * Interface used as props.
 * @name PlansPageProps
 */
export interface PlansPageProps {}

/**
 * Main component.
 * @name PlansPage
 * @param {PlansPageProps}
 * @returns {<PlansPage />}
 */

const PlansPage = ({}: PlansPageProps) => {
  let history = useHistory();
  const dispatch = useAppDispatch();
  const backgroundColorInfoHeader = 'rgb(238,238,238)';
  const currentStep = 1;
  const stepsStyle = {
    color: 'white',
    bgColor: 'rgba(23, 160, 213, 0.7)',
    bgColorActive: 'rgba(23, 160, 213, 1)',
  };
  const hash = useAppSelector(selectToken);
  const initialFormStoreData = useAppSelector(selectInitialFormData);
  const localitiesComboSelector = useAppSelector(selectLocalitiesCombo);
  const bienAsegurado = useAppSelector(selectBienAsegurados);
  const [showInputs, setShowInputs] = useState<boolean>(false);
  const {
    getEmergencyServices,
    getMaintenanceServices,
    paramsPlans,
    plansEnvironment,
  } = usePlansUtils();
  const emergencyService = getEmergencyServices();
  const maintenanceServices = getMaintenanceServices();
  const plans = useAppSelector(selectPlans);
  const selectedPlan = plans?.find(
    (plan: { seleccionado: boolean }) => plan.seleccionado === true
  );

  const [selectedPlanId, setSelectedPlanId] = useState(1);

  const coveragesList = useAppSelector(selectCoveragesCards);
  const [formatedPlans, setFormatedPlans] = useState<Array<any>>([]);
  const variables = useAppSelector(selectEnvVariables) as any;
  const planCardColor = variables.REACT_APP_ACCENT_COLOR;
  const [initialFormData, setInitialFormData] = useState({
    ...initialFormStoreData,
  });

  const valuesInfoHeader = [
    { title: 'CÓDIGO POSTAL', value: initialFormData.zipCode },
    {
      title: 'LOCALIDAD',
      value:
        localitiesComboSelector?.filter(
          (item) => item.value === initialFormData.locality?.toString()
        ).length > 0
          ? localitiesComboSelector?.filter(
              (item) => item.value === initialFormData.locality?.toString()
            )[0]?.name
          : '',
    },
    { title: 'TIPO DE HOGAR', value: initialFormData.propertyType },
  ];

  const stepsPayment = [
    { title: lang.step1Title, icon: Icon1 },
    { title: lang.step2Title, icon: Icon2 },
    { title: lang.step3Title, icon: Icon3 },
  ];

  useEffect(() => {
    setFormatedPlans(formatPlans);
    fetchGetCoverages();
    // Preloads Property form with property data from quote
    dispatch(
      savePropertyFormData({
        state: bienAsegurado?.direccion?.provincia,
        locality: bienAsegurado?.direccion?.localidad,
        zipCode: bienAsegurado?.direccion?.codPostal,
        subcp: bienAsegurado?.direccion?.subcp,
        localizationID: bienAsegurado?.direccion?.idLocalizacion,
        localityID: bienAsegurado?.direccion?.idLocalidad,
        propertyType: bienAsegurado?.tipoHogar,
      })
    );
  }, [plans]);

  useEffect(() => {
    setPlanValue();
  }, []);

  useEffect(() => {
    setInitialFormData({ ...initialFormStoreData });
  }, [initialFormStoreData]);

  useEffect(() => {
    if (initialFormData.zipCode?.length === 4) {
      fetchLocalities(initialFormData.zipCode);
    }
  }, [initialFormData.zipCode]);

  useEffect(() => {
    dispatch(showSpinner());
    setFormatedPlans(formatPlans);
    fetchGetCoverages();
  }, [plans, selectedPlanId]);

  useEffect(() => {
    fetchGetCoverages();
  }, []);

  const setPlanValue = () => {
    if (selectedPlan?.idPlan === 'CERCANIA_2') {
      setSelectedPlanId(0);
    } else if (selectedPlan?.idPlan === 'SEGURIDAD_0_2') {
      setSelectedPlanId(2);
    } else {
      setSelectedPlanId(1);
    }
  };

  const fetchLocalities = async (zipCode: string) => {
    await dispatch(showSpinner());
    await dispatch(getLocalitiesAsync(zipCode));
    await dispatch(hideSpinner());
  };

  const fetchGetCoverages = async () => {
    dispatch(showSpinner());

    if (plans) {
      await dispatch(getCoveragesAsync(plans[selectedPlanId].idPlan));
    }

    dispatch(hideSpinner());
  };

  const formatPlans = () => {
    return (
      plans?.map((plan: any, index: number) => {
        dispatch(hideSpinner());
        return {
          id: index,
          name: plan.nombre,
          price: getTextFormatPrice(plan),
          coverages: plan.coberturas.map((coverage: any) => ({
            name: coverage.nombreCobertura,
            value: coverage.montoAsegurado,
            id: coverage.codigoCobertura,
          })),
          assists: getAssists(
            plan.servicioMantenimiento,
            plan.servicioEmergencia
          ),
          headerText: plan.nombre,
          recomended: plan.recomendado,
          manteinanceService: plan.servicioMantenimiento,
        };
      }) || []
    );
  };

  const onSubmitInfoHeader = async (data: any) => {
    dispatch(showSpinner());
    dispatch(saveInitialFormData({ ...data }));
    await dispatch(
      reQuoteAsync({
        hash: hash,
        propertyType: data.propertyType,
        locality: data.locality,
      })
    );
    await dispatch(getPlansAsync(hash));
    setShowInputs(!showInputs);
    dispatch(hideSpinner());
  };

  const goToNextPage = async () => {
    dispatch(showSpinner());
    await dispatch(postQuoteAsync({ hash: hash, id: selectedPlanId }));
    await dispatch(getPlansAsync(hash));
    dispatch(hideSpinner());
    history.push(`/pago?hashId=${hash}`);
  };

  return (
    <>
      <div
        className='info-header-wrapper'
        style={{ backgroundColor: backgroundColorInfoHeader }}
      >
        <InfoHeader
          backgroundColor={backgroundColorInfoHeader}
          title={lang.infoHeaderTitle}
          insuredAssets={valuesInfoHeader}
          showInputs={showInputs}
          setShowInputs={setShowInputs}
        >
          <InfoHeaderForm
            formValues={initialFormData}
            onSubmit={(data) => {
              onSubmitInfoHeader(data);
            }}
          />
        </InfoHeader>
      </div>
      <StepsPayment
        currentStep={currentStep}
        style={stepsStyle}
        steps={stepsPayment}
      />
      <div className='p-3 p-lg-5 mb-3 bg-page'>
        {plans && (
          <Plans
            plans={formatPlans()}
            selectedPlan={selectedPlanId}
            onChange={(id: number) => setSelectedPlanId(id)}
            color={planCardColor}
          />
        )}
        <div className='row justify-content-center mt-3 mb-3'>
          <p className='col-md-8 col-10 mb-3 legaltxt'>
            {paramsPlans.legalText}
          </p>
          <div className='col-md-4 col-8 d-flex justify-content-md-end'>
            <Button
              className={'button-next button-primary-solid-ap'}
              onClick={goToNextPage}
            >
              {lang.buttonTitle}
            </Button>
          </div>
        </div>
      </div>

      <div className='coverages-view-container'>
        <div className='plan-name-title' style={{ color: planCardColor }}>
          <span>{formatedPlans[selectedPlanId]?.name.toUpperCase()}</span>
        </div>
        <div className='plan-name-subtitle'>
          <span>{lang.subtitle}</span>
          <br />
          <p className='plan-name-adjust-clause'>
            {plansEnvironment.adjustClauseText}
          </p>
        </div>
        <Coverages coverages={coveragesList} color={planCardColor} />
      </div>

      <div className='p-4 p-lg-5 mb-3 bg-page'>
        <RowProductsDown
          title={emergencyService.title}
          subtitle={emergencyService.subtitle}
          values={emergencyService.values}
        />
        <br />
        <br />
        <RowProductsDown
          subtitle={`${maintenanceServices.title} (${maintenanceServices.subtitle})`}
          values={maintenanceServices.values}
          bloquedMessage={maintenanceServices.bloquedMessage}
          showBloquedMessage={
            !formatedPlans[selectedPlanId]?.manteinanceService
          }
        />
        <br />
        <div className='row justify-content-center mt-3 mb-3'>
          <div className='col-md-12 d-flex justify-content-md-end'>
            <Button
              className={'button-next button-primary-solid-ap'}
              onClick={goToNextPage}
            >
              {lang.buttonTitle}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlansPage;
