import { PersonalPaymentDataFormProps } from "../../../Pages/Payment/utils";
import * as lang from "./lang.json";
import { Validations } from "@experta/commons-v2.layout";

/**
 * type used for combo selectors
 * @name ComboType
 */

 export type ComboType = {
  name: string,
  value: string
}

/**
 * type used for RadioInput selectors
 * @name RadioInputType
 */

 export type RadioInputType = {
  label: string,
  value: string
}

/**
 * Interface used as form props.
 * @name PersonalDataProps
 */
 export interface PersonalDataFormProps {
  tipoPersona: string,
  condicionImpositiva: string,
  dni: string,
  cuit: string,
  socialReason: string,
  sex: string,
  name: string,
  lastname: string,
  email: string,
  areaCode: string,
  phone: string,
  nationality: string,
  birthday: string,
  takerActivity: string,
};

/**
 * Input Radio values.
 * @name paramsGenderInputRadio
 */

// export const getParamsGenderInputRadio = (
//   formData: PersonalPaymentDataFormProps
// ) => {
//   return {
//     inputLabel: lang.form.label.inputRadio.label,
//     values: [
//       {
//         id: "femenine",
//         value: "F",
//         name: "sex",
//         labelDesktop: lang.form.label.inputRadio.female,
//         labelMobile: lang.form.label.inputRadio.female,
//         isChecked: formData.sex === "F",
//       },
//       {
//         id: "masculine",
//         value: "M",
//         name: "sex",
//         labelDesktop: lang.form.label.inputRadio.male,
//         labelMobile: lang.form.label.inputRadio.male,
//       },
//     ],
//   };
// };

/**
 * Input Person Type values.
 * @name getPersonTypeOptionSelect
 */

export const getPersonTypeOptionSelect = [
  { name: "Persona Jurídica", value: "JURIDICA" },
  { name: "Persona Física", value: "FISICA" },
];

/**
 * Input Tax Status values.
 * @name getTaxStatusOptionSelect
 */

export const getTaxStatusOptionSelect = (tipoPersona: string) => {
  if (tipoPersona === "JURIDICA") {
    return [
      { name: "Exento", value: "EXCENTO" },
      { name: "Responsable Inscripto", value: "RESPONSABLE_INSCRIPTO" },
    ];
  } else {
    return [
      { name: "Consumidor final", value: "CONSUMIDOR_FINAL" },
      { name: "Exento", value: "EXCENTO" },
      { name: "Monotributista", value: "MONOTRIBUTISTA" },
      { name: "Responsable Inscripto", value: "RESPONSABLE_INSCRIPTO" },
    ];
  }
};

export const initialFormState = {
  tipoPersona: "FISICA",
  condicionImpositiva: "CONSUMIDOR_FINAL",
  dni: "",
  cuit: "",
  socialReason: "",
  sex: "",
  name: "",
  lastname: "",
  email: "",
  areaCode: "",
  phone: "",
  localityID: "",
  street: "",
  streetNumber: "",
  floor: "",
  number: "",
  zipCode: "",
  stateID: "",
  locality: "",
  nationality: "",
  birthday: "",
  takerActivity: "",
};
