import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import * as lang from "./lang.json";

import {Input} from "@experta/commons-v2.input";
import {CheckboxInput} from "@experta/commons-v2.checkbox-input";

import "./PropertyData.css";
import {SecurityMeasures} from "@experta/commons-v2.security-measures";
import {Select} from "@experta/commons-v2.select";
import { PropertyPaymentDataFormProps } from "../../../Pages/Payment/utils";
import { usePropertyDataUtils } from "./utils";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { SecurityMeasuresService } from "../../../Services/SecurityMeasures-service";
import { hideSpinner, showSpinner } from "../../../Store/Spinner/Actions";
import { getSecurityMeasuresService } from "../../../Services";
import { getSecurityMeasuresAsync } from "../../../Store/SecurityMeasures/Actions";
import { selectSecurityMeasures } from "../../../Store/SecurityMeasures/Selectors";
import { selectInitialFormData } from "../../../Store/Forms/Selectors";

//const origin = process.env.REACT_APP_SELLER_ORIGIN;

type PropertyDataProps = {
  formData: PropertyPaymentDataFormProps;
  handleChange: any;
  localitiesCombo: any[];
  localitiesComplete: any[];
  bienAsegurado: any;
};

const PropertyData = (
  {
    formData,
    handleChange,
    localitiesCombo = [],
    bienAsegurado,
    localitiesComplete,
  }: PropertyDataProps,
  ref: any
) => {
  const dispatch = useAppDispatch()
  const initialFormData = useAppSelector(selectInitialFormData);
  const securityMeasuresItems = useAppSelector(selectSecurityMeasures);
  const [formDataErrors, setFormDataErrors] = useState<
    Array<{ field: string; message: string }>
  >([]);
  const [localFormData, setLocalFormData] = useState<PropertyPaymentDataFormProps>(formData);
  const { validateForm, paramsSecurityMeasures } = usePropertyDataUtils();

  useEffect(() => {
    handleChange(localFormData);
  }, [localFormData]);

  useEffect(()=>{
    (async () => {
      dispatch(showSpinner());
      await dispatch(getSecurityMeasuresAsync({propertyType: initialFormData.propertyType, localityId: initialFormData.locality}));
      dispatch(hideSpinner());
    })()
  },[])

  const getMinimumDate = () => {
    const postMeridiem = moment().set({
      hour: 11,
      minute: 59,
      second: 59,
    });
    const isSameOrBeforeMeridiem = moment().isSameOrBefore(
      postMeridiem,
      "seconds"
    )
      ? 0
      : 1;
    const minimumDate = moment()
      .add(isSameOrBeforeMeridiem, "d")
      .format("YYYY-MM-DD");
    return minimumDate;
  };

  const handleForm = async (
    element: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | any
    >
  ) => {
    const name = element.target.name;
    const value = element.target.value;
    const className = element.target.className;
    if (name === "takerLocality") {
      let id = localitiesComplete.findIndex((locality: any) => {
        if (locality.idLocalizacion === value) {
          return true;
        } else {
          return false;
        }
      });
      await setLocalFormData({
        ...localFormData,
        "takerState": localitiesComplete[id].provincia,
        "takerSubcp": localitiesComplete[id].subcp,
        "takerLocalizationID": localitiesComplete[id].idLocalizacion,
        "takerLocalityID": localitiesComplete[id].idLocalidad,
      });
      await setLocalFormData({
        ...localFormData,
        [name]: value,
      });
    } else {
      if (className === "checkbox") {
        switch (name) {
          case "samePlaceCheck":
            setLocalFormData({
              ...localFormData,
              [name]: !localFormData.samePlaceCheck,
            });
            break;
          case "fencesCheck":
            setLocalFormData({
              ...localFormData,
              [name]: !localFormData.fencesCheck,
            });
            break;
          case "securityCheck":
            setLocalFormData({
              ...localFormData,
              [name]: !localFormData.securityCheck,
            });
            break;
          default:
            break;
        }
      } else {
        setLocalFormData({
          ...localFormData,
          [name]: value,
        });
      }
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      validateForm: () => {
        let result = validateForm(localFormData, bienAsegurado?.requiereRejas);
        setFormDataErrors([...result.errorsMessages]);
        return result.errorsMessages?.length === 0;
      },
    }),
    [localFormData]
  );

  const hasError = (name: string) => {
    return formDataErrors.filter((el) => el.field === name).length > 0;
  };

  const getError = (name: string) => {
    if (hasError(name)) {
      return formDataErrors.filter((el) => el.field === name)[0].message;
    } else {
      return undefined;
    }
  };
  return (
    <>
      <div className="property-data-container">
        <div className="state-input disabled-input">
          <Input
            label={lang.propertyForm.labels.state}
            value={localFormData.state}
            name="state"
            onChange={handleForm}
            disabled
          />
        </div>

        <div className="locality-input disabled-input">
          <Input
            label={lang.propertyForm.labels.locality}
            value={localFormData.locality}
            name="locality"
            onChange={handleForm}
            disabled
          />
        </div>
        <div className="date-input">
          <Input
            label={lang.propertyForm.labels.dateFrom}
            value={localFormData.startDate}
            name="startDate"
            onChange={handleForm}
            type="date"
            min={moment().add(1, "days").format("YYYY-MM-DD")}
            max={moment().add(1, "days").add('1', 'month').format("YYYY-MM-DD")}
            error={hasError("startDate")}
            errorMessage={getError("startDate")}
          />
        </div>
        <div className="street-input">
          <Input
            label={lang.propertyForm.labels.street}
            value={localFormData.street}
            name="street"
            onChange={handleForm}
            error={hasError("street")}
            errorMessage={getError("street")}
          />
        </div>
        <div className="street-number-input">
          <Input
            label={lang.propertyForm.labels.streetNumber}
            value={localFormData.streetNumber}
            name="streetNumber"
            onChange={handleForm}
            type="number"
            error={hasError("streetNumber")}
            errorMessage={getError("streetNumber")}
          />
        </div>
        <div className="floor-input">
          <Input
            label={lang.propertyForm.labels.floor}
            value={localFormData.floor}
            name="floor"
            onChange={handleForm}
            placeholder="-"
            type="number"
          />
        </div>
        <div className="department-input">
          <Input
            label={lang.propertyForm.labels.department}
            value={localFormData.number}
            name="number"
            onChange={handleForm}
            placeholder="-"
          />
        </div>
        <div className="zip-code-input">
          <Input
            label={lang.propertyForm.labels.zip}
            value={localFormData.zipCode}
            name="zipCode"
            onChange={handleForm}
            disabled
          />
        </div>
        <div className="same-place-check-input">
          <CheckboxInput
            label={lang.checkbox.samePlaceText}
            name="samePlaceCheck"
            checked={localFormData.samePlaceCheck}
            onChange={handleForm}
          />
        </div>
        {bienAsegurado?.requiereRejas && (
          <div className="fences-check-input">
            <CheckboxInput
              label={lang.checkbox.fencesCheckText}
              name="fencesCheck"
              checked={localFormData.fencesCheck}
              onChange={handleForm}
              // error={errors.fencesCheck}
              // errorMessage="La vivienda debe poseer rejas"
              error={hasError("fencesCheck")}
              errorMessage={getError("fencesCheck")}
            />
          </div>
        )}

        {!localFormData.samePlaceCheck ? (
          <>
            <div className="property-same-place-form-container">
              <div className={"payment-form-title"}>
                <span>{lang.title.taker}</span>
              </div>
            </div>
            <div className="taker-street">
              <Input
                label={lang.propertyForm.labels.street}
                value={localFormData.takerStreet}
                name="takerStreet"
                onChange={handleForm}
                error={hasError("takerStreet")}
                errorMessage={getError("takerStreet")}
              />
            </div>
            <div className="taker-street-number">
              <Input
                label={lang.propertyForm.labels.streetNumber}
                value={localFormData.takerStreetNumber}
                name="takerStreetNumber"
                onChange={handleForm}
                type="number"
                error={hasError("takerStreetNumber")}
                errorMessage={getError("takerStreetNumber")}
              />
            </div>
            <div className="taker-floor">              
              <Input
                label={lang.propertyForm.labels.floor}
                value={localFormData.takerFloor}
                name="takerFloor"
                onChange={handleForm}
                placeholder="-"
                type="number"
              />
            </div>
            <div className="taker-departmen">
              <Input
                label={lang.propertyForm.labels.department}
                value={localFormData.takerNumber}
                name="takerNumber"
                onChange={handleForm}
                placeholder="-"
                type="text"
              />
            </div>
            <div className="taker-zip-code">
              <Input
                label={lang.propertyForm.labels.zip}
                value={localFormData.takerZipCode}
                name="takerZipCode"
                onChange={handleForm}
                type="number"
                maxLength={4}
                error={hasError("takerZipCode")}
                errorMessage={getError("takerZipCode")}
              />
            </div>
            <div className="taker-locality">
              <Select
                name="takerLocality"
                options={localitiesCombo}
                label={lang.propertyForm.labels.locality}
                onChange={handleForm}
                value={localFormData.takerLocality}
              />
            </div>
          </>
        ) : null}

        {paramsSecurityMeasures.items.length > 0 && 
        <div style={{ gridColumnStart: 1, gridColumnEnd: 7 }}>
          <SecurityMeasures title={ paramsSecurityMeasures.title } items={ securityMeasuresItems } />
        </div>}

        <div className="security-check-input">
          <CheckboxInput
            label={lang.checkbox.securityCheckText}
            name="securityCheck"
            checked={localFormData.securityCheck}
            onChange={handleForm}
            error={hasError("securityCheck")}
            errorMessage={getError("securityCheck")}
          />
        </div>
      </div>
    </>
  );
};

export default forwardRef(PropertyData);
