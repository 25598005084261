// React
import { useRef, useState } from 'react';

// Libraries
import { AxiosRequestConfig } from 'axios';
import { useHistory } from 'react-router-dom';

// Components
import { formQuoteAsyncValidation } from './formQuoteAsyncValidation';
import FormQuote from '../../Components/FormQuote/FormQuote';

// Commons
import { Banner } from '@experta/commons-v2.banner';
import { DropDown } from '@experta/commons-v2.drop-down';
import { PlansInfo } from '@experta/commons-v2.plans-info';
import { PriceCard } from '@experta/commons-v2.price-card';
import { RowProducts } from '@experta/commons-v2.row-products';
import { Steps } from '@experta/commons-v2.steps';
import { Subbanner } from '@experta/commons-v2.subbanner';
import { Subtitle } from '@experta/commons-v2.subtitle';

// Style
import './Home.css';

// Store
import { getPlansAsync } from '../../Store/Plans/Actions';
import { hideSpinner, showSpinner } from '../../Store/Spinner/Actions';
import { PostInitialQuoteProps } from '../../Services/Quote-service';
import { saveInitialFormData } from '../../Store/Forms/Slice';
import { saveToken } from '../../Store/Auth/Slice';
import { useAppDispatch } from '../../Store/hooks';

// Utils
import { useHomeUtils } from './utils';

// Services
import { getQuoteService } from '../../Services';

// Language
// import * as lang from "./lang.json";

/**
 * Interface used as props.
 * @name HomePageProps
 */
export interface HomePageProps {}

/**
 * Main component.
 * @name HomePage
 * @param {HomePageProps}
 * @returns {<HomePage />}
 */

const HomePage = ({}: HomePageProps) => {
  let history = useHistory();
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<any>({});
  const quoteFormRef = useRef();
  const {
    paramsBanner,
    paramsPriceCard,
    paramsSubtitle,
    paramsSubBanner,
    paramsSteps,
    paramsRowProducts,
    paramsInnerHTML,
    getParamsPlansInfo,
  } = useHomeUtils();
  const paramsPlansInfo = getParamsPlansInfo();

  const onSubmitForm = async () => {
    dispatch(showSpinner());
    const isValid = await validateQuoteForm();
    if (isValid) {
      let sendFormData: PostInitialQuoteProps = {
        config: {
          headers: {
            'g-recaptcha-response': formData.recaptchaResponse,
          },
        } as AxiosRequestConfig,
        body: {
          tipoProducto: 'HOGAR',
          bienAsegurado: {
            tipoHogar: formData.propertyType,
            direccion: {
              idLocalizacion: formData.locality,
            },
          },
          contacto: {
            nombre: formData.firstName,
            apellido: formData.lastName,
            email: formData.email,
            celular: {
              prefijo: formData.prefix,
              numero: formData.phone,
            },
          },
        },
      };

      await dispatch(saveInitialFormData(formData));
      const hashId = await getQuoteService()?.postInitialQuote(sendFormData);
      await dispatch(saveToken(hashId));
      await dispatch(getPlansAsync(hashId));
      history.push(`/planes?hashId=${hashId}`);
    }
    dispatch(hideSpinner());
  };

  const validateQuoteForm = async (): Promise<boolean> => {
    const errorMessages = await (quoteFormRef.current as any).validateForm();
    if (errorMessages.length !== 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Banner
        imageDesktopURL={paramsBanner.imageDesktopURL}
        imageMobileURL={paramsBanner.imageMobileURL}
        title={paramsBanner.title}
        titleColor={paramsBanner.titleColor}
      />
      <div className='home-container px-md-0 px-lg-3 px-xl-5'>
        <div className='home-first-column'>
          <PriceCard info={paramsPriceCard} style={paramsPriceCard.style} />
          <PlansInfo
            title={paramsPlansInfo.title}
            description={paramsPlansInfo.description}
            mobileInfo={paramsPlansInfo.mobileInfo}
            plans={paramsPlansInfo.plans}
          />
        </div>
        <div className='home-second-column'>
          <div className='subtitle-component-wrapper'>
            <Subtitle
              message={paramsSubtitle.message}
              color={paramsSubtitle.color}
            />
          </div>
          <FormQuote
            ref={quoteFormRef}
            onChange={(data: any) => setFormData(data)}
            onSubmit={() => onSubmitForm()}
            validateMethod={formQuoteAsyncValidation}
          />
        </div>
        <div className='full-width'>
          <Subbanner {...paramsSubBanner} />
        </div>
        <div className='full-width'>
          <Steps stepsList={paramsSteps.stepsList} title={paramsSteps.title} />
        </div>
        <div className='full-width'>
          <RowProducts
            title={paramsRowProducts.title}
            products={paramsRowProducts.products}
          />
        </div>
        <div className='full-width'>
          <DropDown
            title={paramsInnerHTML.title}
            description={paramsInnerHTML.body}
          />
        </div>
      </div>
    </>
  );
};

export default HomePage;
