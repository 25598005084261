import API from "./api";

export class SecurityMeasuresService {

    public getSecurityMeasures = async (propertyType: string, localityId: string) => {
        return API.get(`ecommerce/medidas-seguridad?tipoHogar=${propertyType}&localizacion=${localityId}`)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
    };
}