import { useEffect, useReducer, useState } from "react";
import {Button} from "@experta/commons-v2.button";
import {Select} from "@experta/commons-v2.select";
import {Input} from "@experta/commons-v2.input";
import * as lang from "./lang.json";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {InputRadio} from "@experta/commons-v2.input-radio";
import { hideSpinner, showSpinner } from "../../../Store/Spinner/Actions";
import { getLocalitiesAsync } from "../../../Store/Localities/Actions";
import { selectLocalitiesCombo } from "../../../Store/Localities/Selectors";
import { getInputRadioParams, validateForm } from "./utils";

interface InfoHeaderFormProps {
  formValues: any;
  onSubmit?: (data: any) => void;
}

const InfoHeaderForm = ({ formValues, onSubmit = (data: any) => {} }: InfoHeaderFormProps) => {
  const formReducer = (state: any, event: any) => {
    const newState = {
      ...state,
      [event.name]: event.value,
    };
    return newState;
  };
  const dispatch = useAppDispatch();

  const [formData, setFormData] = useReducer(formReducer, formValues);
  const [formDataErrors, setFormDataErrors] = useState<Array<{field: string, message: string}>>([]);

  const localitiesComboSelector = useAppSelector(selectLocalitiesCombo);

  const fetchLocalities = async (zipCode:string) => {
    await dispatch(showSpinner());
    await dispatch(getLocalitiesAsync(zipCode));
    await dispatch(hideSpinner());
  }
  
  useEffect(() => {
    if (formValues.zipCode.length === 4) {
      fetchLocalities(formValues.zipCode);
    }
  }, [formValues.zipCode]);

  const [localities, setLocalities] = useState<any>([]);

  useEffect(() => {
    setLocalities([...localitiesComboSelector]);
  }, [localitiesComboSelector]);


  const handleChange = (e: any) => {
    if (e.target.name === "zipCode") {
      if (e.target.value.length === 4) {
        setFormData({
          name: e.target.name,
          value: e.target.value,
        });
        fetchLocalities(e.target.value);
      } else {
        if (e.target.value.length < e.target.maxLength) {
          setFormData({
            name: e.target.name,
            value: e.target.value,
          });
        }
      }
    } else {
      setFormData({
        name: e.target.name,
        value: e.target.value,
      });
    }
  };


  const handleSubmit = (e: any) => {
    e.preventDefault();
    let errorMessages = validateForm(formData);
    setFormDataErrors([...errorMessages.errorsMessages]);

    if(errorMessages.errorsMessages.length === 0){
      onSubmit(formData);
    }
  };

  const hasError = (name: string) => {
    return formDataErrors.filter(el => el.field === name).length > 0;
  };

  const getError = (name: string) => {
    if(hasError(name)){
      return formDataErrors.filter(el => el.field === name)[0].message;
    }
    else {
      return undefined;
    }
  };

  return (
    <div className="row">
        <div className="col">
          <Input
            name="zipCode"
            label={lang.form.zipCode}
            onChange={handleChange}
            value={formData.zipCode}
            type="number"
            maxLength={4}
            error={hasError("zipCode")}
            errorMessage={getError("zipCode")}
          />
        </div>
        <div className="col">
          <Select
            name="locality"
            options={localities}
            label={lang.form.locality}
            onChange={handleChange}
            value={formData.locality}
            error={hasError("locality")}
            errorMessage={getError("locality")}
          />
        </div>
        <div className="col">
          <InputRadio
            inputLabel={getInputRadioParams(formData).inputLabel}
            values={getInputRadioParams(formData).values}
            onChange={handleChange}
          />
        </div>
        <div className="col d-flex justify-content-center">
        <Button className="col-10 button-primary-solid-ap" onClick={handleSubmit}>
          {lang.form.accept}
        </Button>
        </div>
    </div>
  );
};

export default InfoHeaderForm;
