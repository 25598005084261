import { RootState } from "../store";

export const selectLocalities = (state: RootState) => state.localities.list;

export const selectLocalitiesCombo = (state: RootState) => {
    let list: { name: string; value: string; }[] = [];
    if(state.localities.list) {
        list = state.localities.list.map((item) =>{
            return {
                name: item.localidad,
                value: item.idLocalizacion
            }
        });
    }

    list.unshift({ name: "", value: "" })
    return list; 
};

export const selectStatesCombo = (state: RootState) => {
    let list = [{ name: "", value: "" }];

    state.localities.list.forEach((item)=>{
        const duplicated = list.filter((_item) => { return item.provincia === _item.name; }).length > 0;
        if(!duplicated) {
            list.push({
                name: item.provincia,
                value: item.codigoPostal
            });
        }
    });
    return list; 
};

export const selectErrorMessageLocalities = (state: RootState) => state.localities.errorMessage;
