import API from "./api";

const seller = process.env.REACT_APP_SELLER_ORIGIN;
export class CoveragesService {


    public getCoverages = async (planID: string) => {
        const res = API.get(`/ecommerce/planes/${planID}`, {
            headers:{
                'marca': seller,
            }
        });
        return res.then(async (resp) => {
            return resp;
        })
        .catch(err => {
            return err;
        })
    };

}