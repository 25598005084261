import { QuoteService } from './Quote-service';
import { LocalitiesService } from './Localities-service';
import { SecurityMeasuresService } from './SecurityMeasures-service';
import { TaxConditionsService } from './TaxConditions-service';
import { PersonTypesService } from './PersonTypes';
import { TakerActivitiesService } from './TakerActivities-service';
import { CoveragesService } from './Coverages-service';
import { PhoneValidatorService } from './PhoneValidator-service';
import { NationalitiesService } from './Nationalities-service';
import { CardValidatorService } from './cardValidator-service';
import { CBUValidatorService } from './cbuValidator';
import { CUITValidatorService } from './CuitValidator';
import { EnvVariablesService } from './EnvVariables-service';

const serviceLocalities = null,
    serviceTakerActivities = null,
    serviceQuote = null,
    serviceSecurityMeasures = null,
    serviceTaxConditions = null,
    servicePersonTypes = null,
    servicePhoneValidator = null,
    serviceCardValidator = null,
    serviceCBUValidator = null,
    serviceNationalities = null,
    serviceCUITValidator = null,
    serviceCoverages = null;

export const getLocalitiesService = () => {
    if (serviceLocalities == null) {
        return new LocalitiesService();
    }
    else {
        return serviceLocalities;
    }
}

export const getQuoteService = () => {
    if (serviceQuote == null) {
        return new QuoteService();
    }
    else {
        return serviceQuote;
    }
}

export const getSecurityMeasuresService = () => {
    if (serviceSecurityMeasures == null) {
        return new SecurityMeasuresService();
    }
    else {
        return serviceSecurityMeasures;
    }
}

export const getPersonTypesService = () => {
    if (servicePersonTypes == null) {
        return new PersonTypesService;
    }
    else {
        return servicePersonTypes;
    }
}

export const getTaxConditionsService = () => {
    if (serviceTaxConditions == null) {
        return new TaxConditionsService;
    }
    else {
        return serviceTaxConditions;
    }
}

export const getTakerActivitiesService = () => {
    if (serviceTakerActivities == null) {

        return new TakerActivitiesService;
    }
    else {

        return serviceTakerActivities;
    }
}

export const getCoveragesService = () => {
    if (serviceCoverages === null) {
        return new CoveragesService;
    } else {
        return serviceCoverages
    }
}

export const getPhoneValidator = () => {
    if (servicePhoneValidator === null) {

        return new PhoneValidatorService;
    }
    else {

        return servicePhoneValidator;
    }
}

export const getCardValidator = () => {
    if (serviceCardValidator === null) {

        return new CardValidatorService;
    }
    else {

        return serviceCardValidator;
    }
}

export const getNationalitiesService = () => {
    if (serviceNationalities == null) {

        return new NationalitiesService;
    }
    else {

        return serviceNationalities;
    }
}

export const getCBUValidator = () => {
    if (serviceCBUValidator === null) {

        return new CBUValidatorService;
    }
    else {

        return serviceCBUValidator;
    }
}

export const getCUITValidator = () => {
    if (serviceCUITValidator === null) {

        return new CUITValidatorService;
    }
    else {

        return serviceCUITValidator;
    }
}

export const getEnvVariablesValidator = () => {
    return new EnvVariablesService();
}