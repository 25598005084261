import { createSlice } from '@reduxjs/toolkit';
import { PaymentCompleteFormData } from '../../Pages/Payment/utils';
import { emmitQuoteAsync } from './Actions';

export interface PaymentState {
  status: 'idle' | 'loading' | 'failed';
  errorMessage: string | undefined;
  canGoBack: boolean,
  paymentFormData: PaymentCompleteFormData,
}

const initialState: PaymentState = {
  status: 'idle',
  errorMessage: '',
  canGoBack: false,
  paymentFormData: {
    personalFormData: {
      tipoPersona: "",
      condicionImpositiva: "",
      dni: "",
      cuit: "",
      socialReason: "",
      sex: "",
      name: "",
      lastname: "",
      email: "",
      areaCode: "",
      phone: "",
      birthday: "",
      nationality: "",
      takerActivity: "",
    },
    propertyFormData: {
      state: "",
      locality: "",
      zipCode: "",
      subcp: "",
      localizationID: "",
      localityID: "",
      propertyType: "",
      startDate: "",
      street: "",
      streetNumber: "",
      floor: "",
      number: "",
      samePlaceCheck: true,
      fencesCheck: false,
      securityCheck: false,
      takerStreet: "",
      takerStreetNumber: "",
      takerFloor: "",
      takerNumber: "",
      takerZipCode: "",
      takerLocality: "",
      takerState: "",
      takerSubcp: "",
      takerLocalizationID: "",
      takerLocalityID: "",
    },
    paymentFormData: {
      paymentMethod: "",
      cardNumber: "",
      expirationMonth: "",
      expirationYear: "",
      cardName: "",
      cardCompany: "",
      bankId: "",
      bankName: "",
      CBU: "",
    }
  },
};


export const paymentSlice = createSlice({
  name: 'Payment',
  initialState,
  reducers: {
    savePaymentFormData: (state, action) => {
      state.paymentFormData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(emmitQuoteAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(emmitQuoteAsync.rejected, (state, action: any) => {
        state.status = 'failed';
        state.errorMessage = action.payload?.errorMessage || "Hubo un problema desconocido";
        state.canGoBack = action.payload?.canGoBack || false
      })
      .addCase(emmitQuoteAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.errorMessage = ''
        state.paymentFormData = action.payload;
      })
  },
});

export const { savePaymentFormData } = paymentSlice.actions;

export default paymentSlice.reducer;
