import { Validations } from "@experta/commons-v2.layout";
import { InitiaLQuoteFormProps } from "../../../Components/FormQuote/utils";
import * as lang from "./lang.json"

/**
 * Input Radio values.
 * @name getInputRadioParams
 */
 export const getInputRadioParams = (formData: { propertyType: any }) => {
  return {
    inputLabel: lang.form.propertyType.labelInput,
    values: [
      {
        id: "hogar-home",
        value: "CASA",
        name: "propertyType",
        labelDesktop: lang.form.propertyType.labelHouseDesktop,
        labelMobile: lang.form.propertyType.labelHouseMobile,
        isChecked: formData.propertyType === "CASA",
      },
      {
        id: "hogar-country",
        value: "COUNTRY",
        name: "propertyType",
        labelDesktop: lang.form.propertyType.labelCountryDesktop,
        labelMobile: lang.form.propertyType.labelCountryMobile,
        isChecked: formData.propertyType === "COUNTRY",
      },
      {
        id: "hogar-first-floor",
        value: "EDIFICIO_PB",
        name: "propertyType",
        labelDesktop: lang.form.propertyType.labelDepto1FloorDesktop,
        labelMobile: lang.form.propertyType.labelDepto1FloorMobile,
        isChecked: formData.propertyType === "EDIFICIO_PB",
      },
      {
        id: "hogar-second-floor",
        value: "EDIFICIO_PA",
        name: "propertyType",
        labelDesktop: lang.form.propertyType.labelDepto2FloorDesktop,
        labelMobile: lang.form.propertyType.labelDepto2FloorMobile,
        isChecked: formData.propertyType === "EDIFICIO_PA",
      }
    ],
  };
};

/**
 * Personal data form validations.
 * @name validateForm
 */
export const validateForm = (localFormData: InitiaLQuoteFormProps) => {
  let errorsMessages = [];

  if (!(Validations.required(localFormData.firstName))) {
    errorsMessages.push({field: "firstName", message: lang.errors.required});
  } else if (!(Validations.fullName(localFormData.firstName) && Validations.maxLength(localFormData.firstName, 29))) {
    errorsMessages.push({field: "firstName", message: lang.errors.invalid});
  }

  if (!(Validations.required(localFormData.lastName))) {
    errorsMessages.push({field: "lastName", message: lang.errors.required});
  } else if (!(Validations.fullName(localFormData.lastName) && Validations.maxLength(localFormData.lastName, 29))) {
    errorsMessages.push({field: "lastName", message: lang.errors.invalid});
  }

  if (!Validations.required(localFormData.email)) {
    errorsMessages.push({field: "email", message: lang.errors.required});
  } else if (!Validations.email(localFormData.email)) {
    errorsMessages.push({field: "email", message: lang.errors.invalid});
  }

  if (!Validations.required(localFormData.prefix)) {
    errorsMessages.push({field: "prefix", message: lang.errors.required});
  } else if (!Validations.phonePrefix(localFormData.prefix)) {
    errorsMessages.push({field: "prefix", message: lang.errors.invalid});
  }

  if (!Validations.required(localFormData.phone)) {
    errorsMessages.push({field: "phone", message: lang.errors.required});
  } else if (!Validations.phone(localFormData.phone)) {
    errorsMessages.push({field: "phone", message: lang.errors.invalid});
  }

  if (!(Validations.required(localFormData.zipCode))) {
    errorsMessages.push({field: "zipCode", message: lang.errors.required});
  } else if (!Validations.number(localFormData.zipCode)) {
    errorsMessages.push({field: "zipCode", message: lang.errors.invalid});
  }

  if (!(Validations.required(localFormData.locality))) {
    errorsMessages.push({field: "locality", message: lang.errors.required});
  }

  return { errorsMessages: errorsMessages };
};