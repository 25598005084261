import API from "./api";

const seller = process.env.REACT_APP_SELLER_ORIGIN;
export class CBUValidatorService {

    public validateCBU = async (cbu:string) => {
        const res = API.get(`/ecommerce/validaciones/pago/cbu/${cbu}`,{headers:{marca: seller}});
        return res.then(async (resp) => {
            return resp;
        })
        .catch(err => {
            console.log(err);
            return err;
        })
    };

}