import { createAsyncThunk } from '@reduxjs/toolkit';
import { getQuoteService } from '../../Services';

const createBaseAsyncMethod = (actionName: string, serviceMethod: any) => {
  return createAsyncThunk(
    actionName,
    async (data: any, { rejectWithValue }: any) => {
      const response = await serviceMethod(data);
      const aceptedStatus = [200, 201, 202, 204];
      const theRequestWasSuccessful = aceptedStatus.some(aceptedCode => response.status === aceptedCode);
      if(theRequestWasSuccessful) {
        return response.data;
      }
      else if(response?.status === 401) {
        return rejectWithValue({refreshToken: true, errorMessage: ''})
      }
      else {
        return rejectWithValue({ refreshToken: false ,errorMessage: 'Error al obtener los planes'})
      }
    }
  );
}

export const getPlansAsync = createBaseAsyncMethod('plans/fetchGetPlans',  getQuoteService()?.getPlans);
export const reQuoteAsync = createBaseAsyncMethod('plans/fetchReQuote',  getQuoteService()?.reQuote);
export const postQuoteAsync = createBaseAsyncMethod('plans/postQuote',  getQuoteService()?.postQuote);
