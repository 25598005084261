import API from "./api";

export class PersonTypesService {

    public getPersonTypes = async () => {
        const res = API.get('/ecommerce/tipos-persona/opciones');
        return res.then(async (resp) => {
            return resp;
        })
        .catch(err => {
            return err;
        })
    };

}