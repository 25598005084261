import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
  token: string;
}

const initialState: AuthState = {
  token: '',
};


export const authSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    saveToken: (state, action) => {
      state.token = action.payload;
    },
  }
});

export const { saveToken } = authSlice.actions;

export default authSlice.reducer;
