import { RootState } from "../store";
import { CoveragesValues } from "./Slice";

type CoverageCardType = {
    price?: number;
    label: string;
    description: string;
    value: string;
}

const getCoveragesCards = (coverages: Array<CoveragesValues>): Array<CoverageCardType> => {
    return coverages.map(coverage => ({
        price: coverage.montoAsegurado, 
        label: coverage.nombreCobertura,
        description: coverage.descripcion,
        value: coverage.nombreCobertura,
    }))
}

export const selectCoveragesCards = (state: RootState) => getCoveragesCards(state.coverages.list);
export const selectCoverages = (state: RootState) => state.coverages.list;
export const selectErrorMessageCoverages = (state: RootState) => state.coverages.errorMessage;