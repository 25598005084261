// React
import { ReactNode, useEffect } from 'react';

// Libraries
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

// Components
import { InitiaLQuoteFormProps } from '../../Components/FormQuote/utils';
import ErrorModal from '../ErrorModal/ErrorModal';

// Commons
import { FloatingButton } from '@experta/commons-v2.floating-button';
import { Footer } from '@experta/commons-v2.footer';
import { Header } from '@experta/commons-v2.header';
import { Layout } from '@experta/commons-v2.layout';
import { Spinner } from '@experta/commons-v2.spinner';

// Style
import './Template.css';

// Store
import { getEnvVariablesAsync } from '../../Store/Variables/actions';
import { getPlansAsync } from '../../Store/Plans/Actions';
import {
  saveInitialFormData,
  savePropertyFormData,
} from '../../Store/Forms/Slice';
import { saveToken } from '../../Store/Auth/Slice';
import { selectEnvVariables } from '../../Store/Variables/selectors';
import { selectToken } from '../../Store/Auth/Selectors';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import {
  selectShowSpinner,
  selectTextSpinner,
} from '../../Store/Spinner/Selectors';

// Utils
import { useTemplateUtils } from './utils';

// Services
import { getQuoteService } from '../../Services';

/**
 * Interface used as props.
 * @name TemplateProps
 */
export interface TemplateProps {
  children: ReactNode;
}

/**
 * Main component.
 * @name Template
 * @param {TemplateProps}
 * @returns {<Template />}
 */

const Template = ({ children }: TemplateProps) => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);

  const showSpinnerValue = useAppSelector(selectShowSpinner);
  const textSpinner = useAppSelector(selectTextSpinner);
  const variables = useAppSelector(selectEnvVariables);

  const history = useHistory();
  const {
    footerParams,
    headerLogoUrl,
    headerParams,
    paramsCommunicationChannels,
  } = useTemplateUtils();

  const { pathname } = useLocation();

  useEffect(() => {
    const url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let hash = params.has('hashId');
    let hash_data = params.get('hashId') as string;

    if (hash) {
      redirectTo(hash_data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!Object.values(variables)?.length) {
      dispatch(getEnvVariablesAsync());
    }
  }, [variables, dispatch]);

  const handler = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ): void => {
    const url = headerLogoUrl ?? '';
    if (url) {
      window.location.href = url;
    } else {
      history.push('/');
    }
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let hash = params.has('hashId');
    if (history.location.pathname !== '/' && !hash) {
      window.location.href = '/';
    }
  }, []);

  const redirectTo = async (hash: string) => {
    try {
      dispatch(saveToken(hash));
      const resp = await getQuoteService()?.getPlans(hash);
      console.log(resp);

      if (resp.data && resp.data.bienAsegurado && resp.data.contacto) {
        let initialFormData: InitiaLQuoteFormProps = {
          email: resp.data.contacto.email,
          firstName: resp.data.contacto.nombre,
          lastName: resp.data.contacto.apellido,
          propertyType: resp.data.bienAsegurado.tipoHogar,
          zipCode: resp.data.bienAsegurado.direccion.codPostal,
          locality: resp.data.bienAsegurado.direccion.idLocalizacion,
          prefix: resp.data.contacto.celular.prefijo,
          phone: resp.data.contacto.celular.numero,
        };

        let locality = {
          state: resp.data.bienAsegurado.direccion.provincia,
          locality: resp.data.bienAsegurado.direccion.localidad,
          zipCode: resp.data.bienAsegurado.direccion.codPostal,
          subcp: resp.data.bienAsegurado.direccion.subcp,
          localizationID: resp.data.bienAsegurado.direccion.idLocalizacion,
          localityID: resp.data.bienAsegurado.direccion.idLocalidad,
          propertyType: resp.data.bienAsegurado.tipoHogar,
        };

        await Promise.all([
          dispatch(saveInitialFormData(initialFormData)),
          dispatch(savePropertyFormData(locality)),
          dispatch(getPlansAsync(hash)),
        ]).then(() => {
          if (resp.data.estadoVenta === 'INICIADO') {
            history.push(`/planes?hashId=${hash}`);
          } else if (
            resp.data.estadoVenta === 'COTIZADO' &&
            !resp.data.emision
          ) {
            history.push(`/pago?hashId=${hash}`);
          } else if (
            resp.data.estadoVenta === 'COTIZADO' &&
            resp.data.emision
          ) {
            history.push(`/gracias?hashId=${hash}`);
          } else {
            history.push('/');
          }
        });
      } else {
        throw new Error('invalid base64 format.');
      }
    } catch (error) {
      window.location.href = window.location.href.split('?')[0];
    }
  };

  const checkRoute = () => {
    return !(history.location.pathname !== '/' && !token);
  };

  return (
    <>
      {checkRoute() && (
        <div className='template-ap container-fluid p-0'>
          <Spinner show={showSpinnerValue} text={textSpinner} />
          <Layout>
            <ErrorModal />
            {Object.values(variables).length ? (
              <Header
                logoClickHandler={handler}
                backgroundColor={headerParams.backgroundColor}
                logoURL={headerParams.logo}
              />
            ) : (
              <></>
            )}
            {children}
            {Object.values(variables).length ? (
              <>
                <FloatingButton
                  communicationChannels={paramsCommunicationChannels.data}
                  alwaysShowTitle={false}
                  // text={ paramsCommunicationChannels.title }
                  icon={paramsCommunicationChannels.mainIcon}
                  mainButtonStyles={{
                    backgroundColor: `${paramsCommunicationChannels.mainColor}`,
                  }}
                />
                <Footer
                  links={footerParams.links}
                  legalText={footerParams.textFooter}
                />
              </>
            ) : (
              <></>
            )}
          </Layout>
        </div>
      )}
    </>
  );
};

export default Template;
