import moment from "moment";
import {PersonalDataFormProps} from "../../Parts/Forms/PersonalData/utils";

const personalData: PersonalDataFormProps = {
  tipoPersona: "FISICA",
  condicionImpositiva: "CONSUMIDOR_FINAL",
  dni: "",
  cuit: "",
  socialReason: "",
  sex: "F",
  name: "",
  lastname: "",
  email: "",
  areaCode: "",
  phone: "",
  birthday: "",
  nationality: "",
  takerActivity: "",
};

const propertyData = {
  state: "",
  locality: "",
  zipCode: "",
  subcp: "",
  localizationID: "",
  localityID: "",
  propertyType: "",
  startDate: moment().add(1, "days").format("YYYY-MM-DD"),
  street: "",
  streetNumber: "",
  floor: "",
  number: "",
  samePlaceCheck: true,
  fencesCheck: false,
  securityCheck: false,
  takerStreet: "",
  takerStreetNumber: "",
  takerFloor: "",
  takerNumber: "",
  takerZipCode: "",
  takerLocality: "",
  takerState: "",
  takerSubcp: "",
  takerLocalizationID: "",
  takerLocalityID: "",
};

const politicallyExposed = {
  politicallyExposed: false,
  reason: "",
};

const obligatedSubject = {
  obligatedSubject: false,
};

const paymentData = {
  paymentMethod: "CREDITO",
  cardNumber: "",
  expirationMonth: "",
  expirationYear: "",
  cardName: "",
  cardCompany: "",
  bankId: "",
  bankName: "",
  CBU: "",
};

export default {
  personalData,
  politicallyExposed,
  propertyData,
  paymentData,
  obligatedSubject,
};
