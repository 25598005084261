import plumbing from "../../assets/images/plumbing.svg";
import locksmith from "../../assets/images/locksmith.svg";
import electricity from "../../assets/images/electricity.svg";
import gas from "../../assets/images/gas.svg";
import glass from "../../assets/images/glass.svg";
import siniestro from "../../assets/images/siniestro.svg";
import { useAppSelector } from "../../Store/hooks";
import { selectEnvVariables } from "../../Store/Variables/selectors";

export const getAssists = (
  hasMaintainanceService: boolean,
  hasEmergencyService: boolean
) => {
  const assists = [];

  if (hasMaintainanceService) assists.push("servicios de mantenimiento");
  if (hasEmergencyService) assists.push("servicios de emergencia");

  return assists;
};

export const getTextFormatPrice = (plan: any) => {
  const formatPrice = plan.premioMensual.toLocaleString('es-ar', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 2
  });

  return `${formatPrice} por mes`;
}

export const usePlansUtils = () => {

  const variables = useAppSelector(selectEnvVariables) as any;

  const plansEnvironment = {
    adjustClauseText: variables.REACT_APP_PLANS_ADJUST_CLAUSE_TEXT,
  }

  const getEmergencyServices = () => {
    return {
      title: variables.REACT_APP_PLANS_EMERGENCY_SERVICE_TITLE,
      subtitle: variables.REACT_APP_PLANS_EMERGENCY_SERVICE_SUBTITLE,
      values: [
        {
          title: variables.REACT_APP_PLANS_EMERGENCY_SERVICE_1_TITLE,
          backgroundColor: variables.REACT_APP_PLANS_EMERGENCY_SERVICE_1_COLOR,
          icon: plumbing,
          text: variables.REACT_APP_PLANS_EMERGENCY_SERVICE_1_TEXT
        },
        {
          title: variables.REACT_APP_PLANS_EMERGENCY_SERVICE_2_TITLE,
          backgroundColor: variables.REACT_APP_PLANS_EMERGENCY_SERVICE_2_COLOR,
          icon: locksmith,
          text: variables.REACT_APP_PLANS_EMERGENCY_SERVICE_2_TEXT
        },
        {
          title: variables.REACT_APP_PLANS_EMERGENCY_SERVICE_3_TITLE,
          backgroundColor: variables.REACT_APP_PLANS_EMERGENCY_SERVICE_3_COLOR,
          icon: electricity,
          text: variables.REACT_APP_PLANS_EMERGENCY_SERVICE_3_TEXT
        },
        {
          title: variables.REACT_APP_PLANS_EMERGENCY_SERVICE_4_TITLE,
          backgroundColor: variables.REACT_APP_PLANS_EMERGENCY_SERVICE_4_COLOR,
          icon: gas,
          text: variables.REACT_APP_PLANS_EMERGENCY_SERVICE_4_TEXT
        },
        {
          title: variables.REACT_APP_PLANS_EMERGENCY_SERVICE_5_TITLE,
          backgroundColor: variables.REACT_APP_PLANS_EMERGENCY_SERVICE_5_COLOR,
          icon: glass,
          text: variables.REACT_APP_PLANS_EMERGENCY_SERVICE_5_TEXT
        },
        {
          title: variables.REACT_APP_PLANS_EMERGENCY_SERVICE_6_TITLE,
          backgroundColor: variables.REACT_APP_PLANS_EMERGENCY_SERVICE_6_COLOR,
          icon: siniestro,
          text: variables.REACT_APP_PLANS_EMERGENCY_SERVICE_6_TEXT
        }
      ]
    }
  };


  const getMaintenanceServices = () => {
    return {
      title: variables.REACT_APP_PLANS_MAINTENANCE_SERVICE_TITLE,
      subtitle: variables.REACT_APP_PLANS_MAINTENANCE_SERVICE_SUBTITLE,
      bloquedMessage: variables.REACT_APP_PLANS_MAINTENANCE_SERVICE_BLOQUED_MESSAGE,
      values: [
        {
          title: variables.REACT_APP_PLANS_MAINTENANCE_SERVICE_1_TITLE,
          backgroundColor: variables.REACT_APP_PLANS_MAINTENANCE_SERVICE_1_COLOR,
          text: variables.REACT_APP_PLANS_MAINTENANCE_SERVICE_1_TEXT
        },
        {
          title: variables.REACT_APP_PLANS_MAINTENANCE_SERVICE_2_TITLE,
          backgroundColor: variables.REACT_APP_PLANS_MAINTENANCE_SERVICE_2_COLOR,
          text: variables.REACT_APP_PLANS_MAINTENANCE_SERVICE_2_TEXT
        },
        {
          title: variables.REACT_APP_PLANS_MAINTENANCE_SERVICE_3_TITLE,
          backgroundColor: variables.REACT_APP_PLANS_MAINTENANCE_SERVICE_3_COLOR,
          text: variables.REACT_APP_PLANS_MAINTENANCE_SERVICE_3_TEXT
        },
        {
          title: variables.REACT_APP_PLANS_MAINTENANCE_SERVICE_4_TITLE,
          backgroundColor: variables.REACT_APP_PLANS_MAINTENANCE_SERVICE_4_COLOR,
          text: variables.REACT_APP_PLANS_MAINTENANCE_SERVICE_4_TEXT
        }
      ]
    }
  };

  const paramsPlans = {
    legalText: variables.REACT_APP_PLANS_LEGALTEXT,
  };


  return {
    plansEnvironment,
    getEmergencyServices,
    getMaintenanceServices,
    paramsPlans,
  }

}