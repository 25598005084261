import { AxiosResponse } from "axios";
import API from "./api";
export interface Locality {
    idLocalizacion: string,
    codigoPostal: string,
    localidad: string,
    provincia: string,
    subcp: string,
}

export class LocalitiesService {

    public getLocalities = async (zipCode:string) => {
        return API.get(`ecommerce/codigoPostal/${zipCode}/localidades`)
        .then((res) => {
            return res;
        })
        .catch((err) => {
          return err;
        });
    };

}


