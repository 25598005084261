import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSecurityMeasuresService } from '../../Services';

const createBaseAsyncMethod = (actionName: string, serviceMethod: any) => {
  return createAsyncThunk(
    actionName,
    async (data: {propertyType: string, localityId: string}, { rejectWithValue }: any) => {
      const response = await serviceMethod(data.propertyType, data.localityId);
      /* manipular response y checkeo de errores */
        if(response?.data && response?.status === 200){
          return response.data;
        }
        else {
          return rejectWithValue({ errorMessage: 'Error al obtener las medidas de seguridad'})
        }
      
    }
  );
}

export const getSecurityMeasuresAsync = createBaseAsyncMethod('securityMeasures/fetchGetSecurityMeasures',  getSecurityMeasuresService()?.getSecurityMeasures);
