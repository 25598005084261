import API from './api';

const { REACT_APP_SELLER_ORIGIN } = process.env;

export class EnvVariablesService {

  public getEnvVariables = async () => {

    API.defaults.headers = {
      ...API.defaults.headers,
      marca: REACT_APP_SELLER_ORIGIN
    }

    try {
      const resp = await API.get('/ecommerce/variables');
      return resp;
    } catch (error) {
      return error;
    }
  }
}