import { createSlice } from '@reduxjs/toolkit';
import { getPlansAsync} from './Actions';

export interface PlansState {
  status: 'idle' | 'loading' | 'failed';
  errorMessage: string | undefined;
  refreshToken: boolean;
  plans: any,
  selectedPlan: any,
  bienAsegurado: any,
  saleStatus: null | string
  emmision: any
}

const initialState: PlansState = {
  status: 'idle',
  errorMessage: '',
  refreshToken: false,
  plans: null,
  selectedPlan: null,
  bienAsegurado: null,
  saleStatus: null,
  emmision: null
};


export const plansSlice = createSlice({
  name: 'Plans',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlansAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPlansAsync.rejected, (state, action: any) => {
        state.status = 'failed';
        state.plans = [];
        state.selectedPlan = null;
        state.bienAsegurado = null;
        state.errorMessage = action.payload.errorMessage;
        state.refreshToken = action.payload.refreshToken
      })
      .addCase(getPlansAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.plans = action.payload.planes;
        state.selectedPlan = action.payload.cotizacion ? action.payload.cotizacion : null;
        state.bienAsegurado = action.payload.bienAsegurado ? action.payload.bienAsegurado : null;
        state.emmision = action.payload.emision ? action.payload.emision : null;
        state.saleStatus = action.payload.estadoVenta ? action.payload.estadoVenta : null;
        state.errorMessage = ''
      })
  },
});



export default plansSlice.reducer;
