import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import {Input} from "@experta/commons-v2.input";
import {Select} from "@experta/commons-v2.select";
import {InputRadio} from "@experta/commons-v2.input-radio";

import { condicionImpositiva, tipoPersona } from "../../../helpers/constants";

import "./PersonalData.css";
import * as lang from "./lang.json"
import { ComboType, initialFormState, PersonalDataFormProps, RadioInputType } from "./utils";
import { PersonalPaymentDataFormProps } from "../../../Pages/Payment/utils";

interface PersonalDataProps {
  formData?: PersonalDataFormProps;
  handleChange: (formData: PersonalDataFormProps) => void;
  taxConditions: Array<ComboType>;
  personTypes: Array<ComboType>;
  takerActivities: Array<ComboType>;
  personSexs: Array<RadioInputType>;
  nationalities: Array<ComboType>;
  validateMethod: (localForm: PersonalDataFormProps, lang: any) => Promise<{errorsMessages: Array<{field: string, message: string}>}>
};

/**
 * Main component.
 * @name personalData
 * @param {PersonalDataProps}
 * @returns {<PersonalData />}
 */
const PersonalData = (props: PersonalDataProps, ref: any) => {
  const {
    formData,
    handleChange,
    personSexs,
    personTypes,
    taxConditions,
    takerActivities,
    nationalities,
    validateMethod
  } = props
  const [formDataErrors, setFormDataErrors] = useState<Array<{ field: string, message: string }>>([]);
  const [localFormData, setLocalFormData] = useState<PersonalDataFormProps>(formData || { ...initialFormState, sex: personSexs[0].value });

  useImperativeHandle(ref, () => ({
    validateForm: async () => {
      let result = await validateMethod(localFormData, lang);
      setFormDataErrors([...result.errorsMessages]);
      return result.errorsMessages?.length === 0;
  },
}), [localFormData]);


  useEffect(() => {
    setLocalFormData(prev => ({ ...prev, sex: personSexs[0].value }))
  }, [personSexs])
  useEffect(() => {
    setLocalFormData(prev => ({ ...prev, condicionImpositiva: taxConditions[0]?.value }))
  }, [personTypes, taxConditions])


  useEffect(() => {
    handleChange(localFormData);
  }, [localFormData]);


  const handlerForm = (element: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const name = element.target.name,
      value = element.target.value;

    switch (element.target.name) {
      case 'tipoPersona':
        setLocalFormData(prev => {
          const newState: PersonalDataFormProps = value !== "FISICA" ? {
            ...prev,
            [name]: value,
            condicionImpositiva: taxConditions[0].value,
            sex: "",
            name: "",
            lastname: "",
            cuit: "",
            dni: ""
          } : {
            ...prev,
            [name]: value,
            condicionImpositiva: taxConditions[0].value,
            sex: personSexs[0].value,
            socialReason: "",
            cuit: ""
          }
          return newState
        })
        break;
      //Update default value
      default:
        setLocalFormData({
          ...localFormData,
          [name]: value
        });
        break;
    }

  };

  const hasError = (name: string) => {
    return formDataErrors.filter(el => el.field === name).length > 0;
  };

  const getError = (name: string) => {
    if (hasError(name)) {
      return formDataErrors.filter(el => el.field === name)[0].message;
    }
    else {
      return undefined;
    }
  };

  return (
    <div className="personal-data-container" data-testid="personal-data-element">
      <div className="payment-form-title payment-form-title-mobile">
        <span>{lang.personalTitle}</span>
      </div>
      <div className="personal-data-subcontainer personal-data-container-mobile" key="PersonalDataForm">
        <div className="person-type-combo">
          <Select
            options={personTypes}
            label={lang.personType}
            value={localFormData.tipoPersona}
            name="tipoPersona"
            onChange={handlerForm}
          />
        </div>
        <div className="condition-combo">
          <Select
            options={taxConditions}
            label={lang.impositiveCondition}
            value={localFormData.condicionImpositiva}
            name="condicionImpositiva"
            onChange={handlerForm}
          />
        </div>
        {
          (localFormData.tipoPersona === 'JURIDICA' || (localFormData.tipoPersona === 'FISICA' && localFormData.condicionImpositiva !== 'CONSUMIDOR_FINAL')) && (
            <>
              <div className="doc-input" >
                <Input
                  label={lang.cuit}
                  value={localFormData.cuit}
                  name="cuit"
                  onChange={handlerForm}
                  type="number"
                  error={hasError("cuit")}
                  errorMessage={getError("cuit")}
                />
              </div>
              {
                (localFormData.tipoPersona === 'JURIDICA') && (
                  <div className="gender-input">
                    <Input
                      label={lang.socialReason}
                      value={localFormData.socialReason}
                      name="socialReason"
                      onChange={handlerForm}
                      type="text"
                      error={hasError("socialReason")}
                      errorMessage={getError("socialReason")}
                    />
                  </div>
                )
              }
            </>
          )
        }
        {
          localFormData.tipoPersona === 'FISICA' && (
            <>
            {localFormData.condicionImpositiva === 'CONSUMIDOR_FINAL' && (
              <div className="doc-input">
              <Input
                label={lang.dni}
                value={localFormData.dni}
                name="dni"
                onChange={handlerForm}
                type="number"
                error={hasError("dni")}
                errorMessage={getError("dni")}
              />
            </div>
            ) }
              <div className="gender-input">
                <div className="input-wrapper">
                  <div className="radio-group">
                    <div>
                      <span className="label">{lang.sex}</span>
                    </div>
                    <div className="radios-personal-data">
                    {personSexs.map((sex, index) =>(
                        <div key={index}>
                          <input
                            type="radio"
                            name="sex"
                            id={sex.value}
                            value={sex.value}
                            onChange={handlerForm}
                            checked={sex.value === localFormData.sex}
                          />
                          <label htmlFor={sex.value}>{sex.label}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }
        {
          (localFormData.tipoPersona === 'FISICA') && (
            <>
              <div className="name-input">
                <Input
                  label={lang.name}
                  value={localFormData.name}
                  name="name"
                  onChange={handlerForm}
                  error={hasError("name")}
                  errorMessage={getError("name")}
                />
              </div>
              <div className="last-name-input">
                <Input
                  label={lang.lastName}
                  value={localFormData.lastname}
                  name="lastname"
                  onChange={handlerForm}
                  error={hasError("lastname")}
                  errorMessage={getError("lastname")}
                />
              </div>
            </>
          )
        }
        <div className="email-input">
          <Input
            label={lang.email}
            value={localFormData.email}
            name="email"
            onChange={handlerForm}
            error={hasError("email")}
            errorMessage={getError("email")}
          />
        </div>
        <div className="prefix-input">
          <Input
            label={lang.areaCode}
            value={localFormData.areaCode}
            name="areaCode"
            onChange={handlerForm}
            error={hasError("areaCode")}
            errorMessage={getError("areaCode")}
          />
        </div>
        <div className="phone-input">
          <Input
            label={lang.phone}
            value={localFormData.phone}
            name="phone"
            onChange={handlerForm}
            error={hasError("phone")}
            errorMessage={getError("phone")}
          />
        </div>
        <div className="nationality-input">
          <Select
            options={nationalities}
            label={lang.nationality}
            value={localFormData.nationality}
            name="nationality"
            onChange={handlerForm}
            error={hasError("nationality")}
            errorMessage={getError("nationality")}
          />
        </div>
        <div className="birthday-input">
          <Input
            label={lang.birthday}
            value={localFormData.birthday}
            type="date"
            name="birthday"
            onChange={handlerForm}
            error={hasError("birthday")}
            errorMessage={getError("birthday")}
            placeholder="-"
          />
        </div>
        <div className="taker-activity-input">
          <Select
            options={takerActivities}
            label={lang.takerActivity}
            value={localFormData.takerActivity}
            name="takerActivity"
            onChange={handlerForm}
            error={hasError("takerActivity")}
            errorMessage={getError("takerActivity")}
          />
        </div>
      </div>
    </div>);
};

      export default forwardRef(PersonalData);
