import * as lang from "./lang.json"
import { Validations } from "@experta/commons-v2.layout";

/**
 * Interface used as form props.
 * @name PersonalDataFormProps
 */
export interface InitiaLQuoteFormProps {
  propertyType: string,
  zipCode: string,
  locality: string,
  firstName: string,
  lastName: string,
  email: string,
  prefix: string,
  phone: string,
  recaptchaResponse?: string
};

export type FormQuoteProps = {
  onChange?: (data: any) => void;
  onSubmit?: () => void;
  validateMethod: (localForm: InitiaLQuoteFormProps, lang: any) => Promise<{errorsMessages: Array<{field: string, message: string}>}>
}

/**
 * Quantity Values.
 * @name getQuantityValues
 */
export const getQuantityValues = () => {
  return [
    {
      name: lang.form.EmptyComboName,
      value: "",
    },
    {
      name: "1",
      value: "1",
    },
    {
      name: "2",
      value: "2",
    },
    {
      name: "3",
      value: "3",
    },
    {
      name: "4",
      value: "4",
    },
    {
      name: "5",
      value: "5",
    },
    {
      name: "6",
      value: "6",
    },
    {
      name: "7",
      value: "7",
    },
    {
      name: "8",
      value: "8",
    },
    {
      name: "9",
      value: "9",
    },
    {
      name: "10",
      value: "10",
    },
  ];
};

/**
 * Input Radio values.
 * @name getInputRadioParams
 */
export const getInputRadioParams = (formData: { propertyType: any }) => {
  return {
    inputLabel: lang.form.propertyType.labelInput,
    values: [
      {
        id: "hogar-home",
        value: "CASA",
        name: "propertyType",
        labelDesktop: lang.form.propertyType.labelHouseDesktop,
        labelMobile: lang.form.propertyType.labelHouseMobile,
        isChecked: formData.propertyType === "CASA",
      },
      {
        id: "hogar-country",
        value: "COUNTRY",
        name: "propertyType",
        labelDesktop: lang.form.propertyType.labelCountryDesktop,
        labelMobile: lang.form.propertyType.labelCountryMobile,
      },
      {
        id: "hogar-first-floor",
        value: "EDIFICIO_PB",
        name: "propertyType",
        labelDesktop: lang.form.propertyType.labelDepto1FloorDesktop,
        labelMobile: lang.form.propertyType.labelDepto1FloorMobile,
      },
      {
        id: "hogar-second-floor",
        value: "EDIFICIO_PA",
        name: "propertyType",
        labelDesktop: lang.form.propertyType.labelDepto2FloorDesktop,
        labelMobile: lang.form.propertyType.labelDepto2FloorMobile,
      }
    ],
  };
};

/**
 * Initial state of personal data.
 * @name initialFormQuoteState
 */
export const initialFormQuoteState: InitiaLQuoteFormProps = {
  propertyType: "CASA",
  zipCode: "",
  locality: "",
  firstName: "",
  lastName: "",
  email: "",
  prefix: "",
  phone: "",
  recaptchaResponse: ""
}