import {
  PaymentDataForm /*, PoliticallyExposedDataForm*/,
} from "@experta/commons-v2.payment-data/dist/utils";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";
import { InitiaLQuoteFormProps } from "../../Components/FormQuote/utils";
import { PropertyPaymentDataFormProps } from "../../Pages/Payment/utils";
import { PersonalDataFormProps } from "../../Parts/Forms/PersonalData/utils";
import initialFormStates from "./utils";

export interface FormState {
  initialForm: Partial<InitiaLQuoteFormProps> | null;
  personalData: Partial<PersonalDataFormProps>;
  propertyData: Partial<PropertyPaymentDataFormProps>;
  politicallyExposedData: Partial<{
    politicallyExposed: boolean;
    reason: string;
  }>;
  paymentData: Partial<PaymentDataForm>;
  obligatedSubjectData: Partial<{
    obligatedSubject: boolean;
  }>;
}

const initialState: FormState = {
  initialForm: null,
  personalData: initialFormStates.personalData,
  propertyData: initialFormStates.propertyData,
  politicallyExposedData: initialFormStates.politicallyExposed,
  paymentData: initialFormStates.paymentData,
  obligatedSubjectData: initialFormStates.obligatedSubject,
};

export const formsSlice = createSlice({
  name: "Forms",
  initialState,
  reducers: {
    saveInitialFormData: (
      state,
      action: PayloadAction<FormState["initialForm"]>
    ) => {
      state.initialForm = action.payload;

      // Preloads personal data form with initial form editable data
      state.personalData = {
        ...state.personalData,
        name: action.payload?.firstName,
        lastname: action.payload?.lastName,
        email: action.payload?.email,
        areaCode: action.payload?.prefix,
        phone: action.payload?.phone,
      };
    },
    savePersonalFormData: (
      state,
      action: PayloadAction<FormState["personalData"]>
    ) => {
      state.personalData = action.payload;
    },
    savePropertyFormData: (
      state,
      action: PayloadAction<FormState["propertyData"]>
    ) => {
      state.propertyData = {
        ...state.propertyData,
        ...action.payload,
      };

      // return {
      //   ...state,
      //   propertyData: {
      //     ...state.propertyData,
      //     ...action.payload
      //   }
      // }
    },
    savePoliticallyExposedFormData: (
      state,
      action: PayloadAction<FormState["politicallyExposedData"]>
    ) => {
      state.politicallyExposedData = action.payload;
    },
    saveObligatedSubjectFormData: (
      state,
      action: PayloadAction<FormState["obligatedSubjectData"]>
    ) => {
      state.obligatedSubjectData = action.payload;
    },
    savePaymentFormDataStore: (
      state,
      action: PayloadAction<FormState["paymentData"]>
    ) => {
      state.paymentData = action.payload;
    },
  },
});

export const {
  saveInitialFormData,
  savePersonalFormData,
  savePropertyFormData,
  savePoliticallyExposedFormData,
  savePaymentFormDataStore,
  saveObligatedSubjectFormData,
} = formsSlice.actions;

export default formsSlice.reducer;
