import { selectEnvVariables } from "../../Store/Variables/selectors";
import { useAppSelector } from "../../Store/hooks";


export const useFinalUtils = () => {

  const variables = useAppSelector(selectEnvVariables) as any;

  /**
   * Params to use in payment-result component.
   * @name paymentResultParams
  */
  const paymentResultParams = {
    titleSuccess: variables.REACT_APP_FINAL_TITLE_SUCCESS,
    titleError: variables.REACT_APP_FINAL_TITLE_ERROR,
    highlightColor: variables.REACT_APP_ACCENT_COLOR,
    textError: variables.REACT_APP_FINAL_TEXT_ERROR
  };

  return {
    paymentResultParams,
  }
}