import { PaymentDataForm } from "@experta/commons-v2.payment-data/dist/utils";
import { useAppSelector } from "../../Store/hooks";
import { selectEnvVariables } from "../../Store/Variables/selectors";

/**
 * Interface used as form props.
 * @name PersonalDataProps
 */
 export interface PersonalPaymentDataFormProps {
  tipoPersona: string,
  condicionImpositiva: string,
  dni: string,
  cuit: string,
  socialReason: string,
  sex: string,
  name: string,
  lastname: string,
  email: string,
  areaCode: string,
  phone: string,
  birthday:string,
  nationality:string,
  takerActivity: string,
};

/**
 * Interface used as form props.
 * @name PropertyPaymentDataFormProps
 */
 export interface PropertyPaymentDataFormProps {
  state: string,
  locality: string,
  zipCode: string,
  subcp: string,
  localizationID: string,
  localityID: string,
  propertyType: string,
  startDate: string,
  street: string,
  streetNumber: string,
  floor: string,
  number: string,
  samePlaceCheck: boolean,
  fencesCheck: boolean,
  securityCheck: boolean,
  takerStreet: string,
  takerStreetNumber: string,
  takerFloor: string,
  takerNumber: string,
  takerZipCode: string,
  takerLocality: string,
  takerState: string,
  takerSubcp: string,
  takerLocalizationID: string,
  takerLocalityID: string,
};

/**
 * Interface used as form props.
 * @name PaymentCompleteFormData
*/
export interface PaymentCompleteFormData {
  personalFormData : PersonalPaymentDataFormProps
  propertyFormData: PropertyPaymentDataFormProps
  paymentFormData: PaymentDataForm
}


/**
 * Fill bank combo.
 * @name banks
*/
export const banks =
process.env.REACT_APP_SELLER_ORIGIN === "REBA" ? [{ value: "415", name: "REBA" }]
  : [
  { value: "", name: "Seleccionar" },
  { value: "007", name: "BANCO DE GALICIA Y BUENOS AIRES S.A.U." },
  { value: "011", name: "BANCO DE LA NACION ARGENTINA" },
  { value: "014", name: "BANCO DE LA PROVINCIA DE BUENOS AIRES" },
  { value: "015", name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA" },
  { value: "016", name: "CITIBANK N.A." },
  { value: "017", name: "BANCO BBVA ARGENTINA S.A." },
  { value: "020", name: "BANCO DE LA PROVINCIA DE CORDOBA S.A." },
  { value: "027", name: "BANCO SUPERVIELLE S.A." },
  { value: "029", name: "BANCO DE LA CIUDAD DE BUENOS AIRES" },
  { value: "034", name: "BANCO PATAGONIA S.A." },
  { value: "044", name: "BANCO HIPOTECARIO S.A." },
  { value: "045", name: "BANCO DE SAN JUAN S.A." },
  { value: "065", name: "BANCO MUNICIPAL DE ROSARIO" },
  { value: "072", name: "BANCO SANTANDER RIO S.A." },
  { value: "083", name: "BANCO DEL CHUBUT S.A." },
  { value: "086", name: "BANCO DE SANTA CRUZ S.A." },
  { value: "093", name: "BANCO DE LA PAMPA SOCIEDAD DE ECONOMÍA M	" },
  { value: "094", name: "BANCO DE CORRIENTES S.A." },
  { value: "097", name: "BANCO PROVINCIA DEL NEUQUÉN SOCIEDAD ANÓ	" },
  { value: "143", name: "BRUBANK S.A.U." },
  { value: "147", name: "BANCO INTERFINANZAS S.A." },
  { value: "150", name: "HSBC BANK ARGENTINA S.A." },
  { value: "165", name: "JPMORGAN CHASE BANK, NATIONAL ASSOCIATIO" },
  { value: "191", name: "BANCO CREDICOOP COOPERATIVO LIMITADO" },
  { value: "198", name: "BANCO DE VALORES S.A." },
  { value: "247", name: "BANCO ROELA S.A." },
  { value: "254", name: "BANCO MARIVA S.A." },
  { value: "259", name: "BANCO ITAU ARGENTINA S.A." },
  { value: "262", name: "BANK OF AMERICA, NATIONAL ASSOCIATION" },
  { value: "266", name: "BNP PARIBAS" },
  { value: "268", name: "BANCO PROVINCIA DE TIERRA DEL FUEGO" },
  { value: "269", name: "BANCO DE LA REPUBLICA ORIENTAL DEL URUGU" },
  { value: "277", name: "BANCO SAENZ S.A." },
  { value: "281", name: "BANCO MERIDIAN S.A." },
  { value: "285", name: "BANCO MACRO S.A." },
  { value: "299", name: "BANCO COMAFI SOCIEDAD ANONIMA" },
  { value: "300", name: "BANCO DE INVERSION Y COMERCIO EXTERIOR S	" },
  { value: "301", name: "BANCO PIANO S.A." },
  { value: "305", name: "BANCO JULIO SOCIEDAD ANONIMA" },
  { value: "309", name: "BANCO RIOJA SOCIEDAD ANONIMA UNIPERSONAL	" },
  { value: "310", name: "BANCO DEL SOL S.A." },
  { value: "311", name: "NUEVO BANCO DEL CHACO S. A." },
  { value: "312", name: "BANCO VOII S.A." },
  { value: "315", name: "BANCO DE FORMOSA S.A." },
  { value: "319", name: "BANCO CMF S.A." },
  { value: "321", name: "BANCO DE SANTIAGO DEL ESTERO S.A." },
  { value: "322", name: "BANCO INDUSTRIAL S.A." },
  { value: "330", name: "NUEVO BANCO DE SANTA FE SOCIEDAD ANONIMA" },
  { value: "331", name: "BANCO CETELEM ARGENTINA S.A." },
  { value: "332", name: "BANCO DE SERVICIOS FINANCIEROS S.A." },
  { value: "336", name: "BANCO BRADESCO ARGENTINA S.A.U." },
  { value: "338", name: "BANCO DE SERVICIOS Y TRANSACCIONES S.A." },
  { value: "339", name: "RCI BANQUE S.A." },
  { value: "340", name: "BACS BANCO DE CREDITO Y SECURITIZACION S" },
  { value: "341", name: "BANCO MASVENTAS S.A." },
  { value: "384", name: "WILOBANK S.A." },
  { value: "386", name: "NUEVO BANCO DE ENTRE RÍOS S.A." },
  { value: "389", name: "BANCO COLUMBIA S.A." },
  { value: "426", name: "BANCO BICA S.A." },
  { value: "431", name: "BANCO COINAG S.A." },
  { value: "432", name: "BANCO DE COMERCIO S.A." },
  { value: "435", name: "BANCO SUCREDITO REGIONAL S.A.U." },
  { value: "515", name: "BANK OF CHINA LIMITED SUCURSAL BUENOS AI" },
];



/**
 * Fill credit card combo.
 * @name cardBrands
*/
export const cardBrands = process.env.REACT_APP_SELLER_ORIGIN === "REBA" ? [
  { value: "", name: "Seleccionar" },
  { value: "VISA", name: "VISA" },
  { value: "AMEX", name: "AMEX" },
] : [
  { value: "", name: "Seleccionar" },
  { value: "VISA", name: "VISA" },
  { value: "MASTERCARD", name: "MASTERCARD" },
  { value: "AMEX", name: "AMEX" },
  { value: "TARJETA_NARANJA", name: "TARJETA NARANJA" },
  { value: "CABAL", name: "CABAL" },
];



/**
 * Fill bank combo.
 * @name paymentMethods
 */
export const paymentMethods = [
  {
    "value": "DEBITO",
    "label": "CBU"
  },
  {
    "value": "CREDITO",
    "label": "Tarjeta de crédito"
  }
]

export const monthDiff = (date: any) => {
  let months;
  let actualDate = new Date();
  let futureDate = new Date(date);
  months = (futureDate.getFullYear() - actualDate.getFullYear()) * 12;
  months -= actualDate.getMonth();
  months += futureDate.getMonth();
  return months <= 0 ? 0 : months;
};

export const formatPlanName = (plan: string) :string =>{
  const plans :any= {
    default : {
      BASIC: 'CERCANÍA',
      PLUS: 'PROTECCIÓN',
      PREMIUM: 'SEGURIDAD'
    },
    REBA : {
      BASIC: 'ESENCIAL',
      PLUS: 'CLÁSICO',
      PREMIUM: 'PLUS'
    },
  } 
  return origin==='REBA' ? plans.REBA[plan]  : plans.default[plan]
}
  
export const usePaymentUtils = () => {

  const variables = useAppSelector(selectEnvVariables) as any;

  /**
   * Params to use in payment-result component.
   * @name paymentResultParams
   */
  const tycParams = {
    title: variables.REACT_APP_DATA_TYC_TITLE,
    description: variables.REACT_APP_DATA_TYC_DESCRIPTION,
    altDescription: variables.REACT_APP_DATA_TYC_ALT_DESCRIPTION,
  };

  const subjectObligatedParams = {
    title: variables.REACT_APP_DATA_OS_TITLE,
    description: variables.REACT_APP_DATA_OS_DESCRIPTION,
  }


  return {
    tycParams,
    subjectObligatedParams,
  }

}