import { Validations } from "@experta/commons-v2.layout";
import { PersonalDataFormProps } from "../../Parts/Forms/PersonalData/utils";
import { getCUITValidator, getPhoneValidator } from "../../Services";

/**
 * Personal data form validations.  
 * @name validateForm
 */
 export const personalDataFormAsyncValidation = async (localFormData: PersonalDataFormProps, lang: any) => {

    const errorsMessages = [];

    let phonePrefixExists = false;
    let phoneNumberExists = false;
  
    if (localFormData.condicionImpositiva === "CONSUMIDOR_FINAL") {
        if (!(Validations.required(localFormData.dni))) {
            errorsMessages.push({ field: "dni", message: lang.default.errors.required });
        } else if (!(Validations.number(localFormData.dni) && Validations.minLength(localFormData.dni, 7) && Validations.maxLength(localFormData.dni, 8))) {
            errorsMessages.push({ field: "dni", message: lang.default.errors.required });
        }
    }

    if (localFormData.tipoPersona === "JURIDICA") {

        if (!(Validations.required(localFormData.socialReason))) {
            errorsMessages.push({ field: "socialReason", message: lang.default.errors.required });
        } else if (!(Validations.alphaNumeric(localFormData.socialReason, true) && Validations.maxLength(localFormData.socialReason, 60))) {
            errorsMessages.push({ field: "socialReason", message: lang.default.errors.required });
        }

    } else {
        if (!(Validations.required(localFormData.name))) {
            errorsMessages.push({ field: "name", message: lang.default.errors.required });
        } else if (!(Validations.fullName(localFormData.name) && Validations.maxLength(localFormData.name, 29))) {
            errorsMessages.push({ field: "name", message: lang.default.errors.required });
        }

        if (!(Validations.required(localFormData.lastname))) {
            errorsMessages.push({ field: "lastname", message: lang.default.errors.required });
        } else if (!(Validations.fullName(localFormData.lastname) && Validations.maxLength(localFormData.lastname, 29))) {
            errorsMessages.push({ field: "lastname", message: lang.default.errors.required });
        }
    }

    if (!Validations.required(localFormData.email)) {
        errorsMessages.push({ field: "email", message: lang.default.errors.required });
    } else if (!Validations.email(localFormData.email)) {
        errorsMessages.push({ field: "email", message: lang.default.errors.required });
    }

    if (!Validations.required(localFormData.areaCode)) {
        errorsMessages.push({ field: "areaCode", message: lang.errors.required });
        phonePrefixExists = false;
    } else {
        phonePrefixExists = true;
    }

    if (!Validations.required(localFormData.phone)) {
        errorsMessages.push({
            field: "phone",
            message: lang.default.errors.required,
        });
        phoneNumberExists = false;
    } else {
        phoneNumberExists = true;
    }
  
    if (!(Validations.required(localFormData.nationality))) {
      errorsMessages.push({field: "nationality", message: lang.default.errors.required});
    } else if (!(Validations.number(localFormData.nationality))) {
      errorsMessages.push({field: "nationality", message: lang.default.errors.invalid});
    }
  
    if (!(Validations.required(localFormData.takerActivity))) {
      errorsMessages.push({field: "takerActivity", message: lang.default.errors.required});
    } else if (!(Validations.number(localFormData.takerActivity))) {
      errorsMessages.push({field: "takerActivity", message: lang.default.errors.invalid});
    }
  
    const youngerDate =  (new Date(localFormData.birthday));
    youngerDate.setFullYear((new Date(localFormData.birthday)).getFullYear() + 18);
    const isYounger =  youngerDate <= new Date();
  
    const olderDate =  (new Date(localFormData.birthday));
    olderDate.setFullYear((new Date(localFormData.birthday)).getFullYear() + 65);
    const isOlder =  olderDate >= new Date();
  
    if(!(Validations.required(localFormData.birthday))) {
      
      errorsMessages.push({field: "birthday", message: lang.default.errors.required});
    } else if(!(( isYounger && isOlder))) {
      errorsMessages.push({field: "birthday", message: lang.default.errors.legalAge});
    }

    /* Async validations */

    // cuit validation
    if (localFormData.condicionImpositiva !== "CONSUMIDOR_FINAL") {
        const CUITValidator = getCUITValidator();

        try {
            const response = await CUITValidator?.validateCUIT(localFormData.cuit, localFormData.tipoPersona);

            if (!response.data?.valido) {
                errorsMessages.push({ field: "cuit", message: response.data?.error });
            }
        } catch (error) {
            errorsMessages.push({
                field: "cuit",
                message: "No se pudo validar el número de cuit.",
            });
        }
    }

    if (phonePrefixExists && phoneNumberExists) {
        const phoneValidator = getPhoneValidator();

        try {
            const response = await phoneValidator?.validatePhone(
                localFormData.areaCode,
                localFormData.phone
            );
            if (!response.data?.valido) {
                const field = response.data?.campo === "numero" ? "phone" : "areaCode";

                if (field) {
                    errorsMessages.push({
                        field: field,
                        message: response.data?.error || "Campo inválido",
                    });
                } else {
                    errorsMessages.push({
                        field: "phone",
                        message: "Campo inválido",
                    });
                    errorsMessages.push({
                        field: "prefix",
                        message: "Campo inválido",
                    });
                }
            }
        } catch (error) {
            errorsMessages.push({ field: "phone", message: "Teléfono inválido" });
        }
    }
  
    return { errorsMessages: errorsMessages };
  };