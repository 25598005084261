import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCoveragesService } from '../../Services';

const createBaseAsyncMethod = (actionName: string, serviceMethod: any) => {
  return createAsyncThunk(
    actionName,
    async (planId: string, { rejectWithValue }: any) => {
      const response = await serviceMethod(planId);
      /* manipular response y checkeo de errores */
      
        if(response?.data && response?.status === 200){
          return response.data.coberturas;
        }
        else {
          return rejectWithValue({ errorMessage: 'Error al obtener las coberturas'})
        }
      
    }
  );
}

export const getCoveragesAsync = createBaseAsyncMethod('coverages/fetchGetCoverages',  getCoveragesService()?.getCoverages);


