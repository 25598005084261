import * as lang from "./lang.json";
import { Validations } from "@experta/commons-v2.layout";
import { PropertyPaymentDataFormProps } from "../../../Pages/Payment/utils";
import { useAppSelector } from "../../../Store/hooks";
import { selectEnvVariables } from "../../../Store/Variables/selectors";


export const usePropertyDataUtils = () => {

  const variables = useAppSelector(selectEnvVariables) as any;


  /**
  * Params to use in security measures component.
  * @name paramsSecurityMeasures
  */
  const paramsSecurityMeasures = {
    title: variables.REACT_APP_PAYMENT_SECURITY_MEASURES_TITLE,
    items: JSON.parse(variables.REACT_APP_PAYMENT_SECURITY_MEASURES_PARAGRAPHS),
  };


  /**
   * Property data form validations.
   * @name validateForm
  */
  const validateForm = (localFormData: PropertyPaymentDataFormProps, requireFences: boolean) => {
    let errorsMessages = [];

    if (!Validations.required(localFormData.street)) {
      errorsMessages.push({ field: "street", message: lang.errors.required });
    } else if (!Validations.alphaNumeric(localFormData.street, true)) {
      errorsMessages.push({ field: "street", message: lang.errors.invalid });
    }

    if (!Validations.required(localFormData.streetNumber)) {
      errorsMessages.push({
        field: "streetNumber",
        message: lang.errors.required,
      });
    } else if (!Validations.number(localFormData.streetNumber)) {
      errorsMessages.push({
        field: "streetNumber",
        message: lang.errors.invalid,
      });
    }

    if (!localFormData.samePlaceCheck) {
      if (!Validations.required(localFormData.takerZipCode)) {
        errorsMessages.push({
          field: "takerZipCode",
          message: lang.errors.required,
        });
      } else if (!Validations.number(localFormData.takerZipCode)) {
        errorsMessages.push({
          field: "takerZipCode",
          message: lang.errors.invalid,
        });
      }

      if (!Validations.required(localFormData.takerLocality)) {
        errorsMessages.push({
          field: "takerLocality",
          message: lang.errors.required,
        });
      }

      if (!Validations.required(localFormData.takerStreet)) {
        errorsMessages.push({
          field: "takerStreet",
          message: lang.errors.required,
        });
      } else if (!Validations.alphaNumeric(localFormData.takerStreet, true)) {
        errorsMessages.push({
          field: "takerStreet",
          message: lang.errors.invalid,
        });
      }

      if (!Validations.required(localFormData.takerStreetNumber)) {
        errorsMessages.push({
          field: "takerStreetNumber",
          message: lang.errors.required,
        });
      } else if (!Validations.number(localFormData.takerStreetNumber)) {
        errorsMessages.push({
          field: "takerStreetNumber",
          message: lang.errors.invalid,
        });
      }
    }

    if (requireFences && !localFormData.fencesCheck) {
      errorsMessages.push({
        field: "fencesCheck",
        message: lang.errors.required,
      });
    }

    if (!localFormData.securityCheck) {
      errorsMessages.push({
        field: "securityCheck",
        message: lang.errors.required,
      });
    }

    return { errorsMessages: errorsMessages };
  };

  return {
    paramsSecurityMeasures,
    validateForm
  }


}