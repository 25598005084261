import { createSlice } from '@reduxjs/toolkit';
import {getLocalitiesAsync} from './Actions';
import { Locality } from '../../Services/Localities-service'

export interface LocalitiesState {
  status: 'idle' | 'loading' | 'failed';
  list: Array<Locality>;
  errorMessage: string | undefined;
}

const initialState: LocalitiesState = {
  status: 'idle',
  list: [],
  errorMessage: ''
};


export const localitiesSlice = createSlice({
  name: 'Localities',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      // Token
      .addCase(getLocalitiesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getLocalitiesAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = 'Error al obtener las localidades';
        state.list = [];
      })
      .addCase(getLocalitiesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload;
        state.errorMessage = '';
      });
  },
});

export default localitiesSlice.reducer;
