import {configureStore, ThunkAction, Action} from "@reduxjs/toolkit";
import authReducer from "./Auth/Slice";
import spinnerReducer from "./Spinner/Slice";
import localitiesReducer from "./Localities/Slice";
import plansReducer from "./Plans/Slice";
import paymentReducer from "./Payment/Slice";
import taxConditionsReducer from "./TaxConditions/Slice";
import personTypesReducer from "./PersonTypes/Slice";
import takerActivitiesReducer from "./TakerActivities/Slice";
import securityMeasuresReducer from "./SecurityMeasures/Slice";
import coveragesReducer from "./Coverages/Slice";
import nationalitiesReducer from "./Nationalities/Slice";
import formsReducer from "./Forms/Slice";
import envVariablesReducer from "./Variables/slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    spinner: spinnerReducer,
    localities: localitiesReducer,
    forms: formsReducer,
    plans: plansReducer,
    payment: paymentReducer,
    personTypes: personTypesReducer,
    taxConditions: taxConditionsReducer,
    takerActivities: takerActivitiesReducer,
    securityMeasures: securityMeasuresReducer,
    coverages: coveragesReducer,
    nationalities: nationalitiesReducer,
    variables: envVariablesReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
