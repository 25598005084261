// React
import { useHistory } from "react-router";

// Commons
import {PaymentResult} from "@experta/commons-v2.payment-result";

// Style
import "./Final.css";

// Store
import { selectCangoGoBack, selectPaymentErrorMessage } from "../../Store/Payment/Selectors";
import { selectEmmision, selectSelectedPlan } from "../../Store/Plans/Selectors";
import { selectToken } from "../../Store/Auth/Selectors";
import { useAppSelector } from "../../Store/hooks";

// Utils
import { useFinalUtils } from "./utils";

// Language
import * as lang from "./lang.json";

/**
 * Interface used as props.
 * @name FinalPageProps
 */
export interface FinalPageProps {}

/**
 * Main component.
 * @name FinalPage
 * @param {FinalPageProps}
 * @returns {<FinalPage />}
 */

const FinalPage = ({}: FinalPageProps) => {
  const history = useHistory()
  const selectedPlan = useAppSelector(selectSelectedPlan)
  const emmision = useAppSelector(selectEmmision);
  const canGoBack = useAppSelector(selectCangoGoBack);
  const paymentError = useAppSelector(selectPaymentErrorMessage)
  const { paymentResultParams } = useFinalUtils();
  const hash = useAppSelector(selectToken);


  const formatSuccessMessage = () => {
    return (
      lang.successText
        .replace('SELECTED_PLAN_NAME', `<strong style="color: ${paymentResultParams.highlightColor}">${selectedPlan?.plan.nombre || ''}</strong>`)
        .replace('POLICY_NUMBER', `<strong style="color: ${paymentResultParams.highlightColor}">${emmision?.numeroPoliza || ''}</strong>`)
    )
  }

  return (
    <div className="final-container">
        <PaymentResult
        titleSuccess={formatSuccessMessage()}
        titleError={paymentResultParams.titleError}
        highlightColor={paymentResultParams.highlightColor}
        textError={paymentError || paymentResultParams.textError}
        isSuccessful={emmision?.estadoEmision === "EMITIDO"}
        email={emmision?.tomador.email}
      />
      {emmision?.estadoEmision === "EMITIDO" && (
        <div className="button-container">
          <div style={{ textAlign: "center" }}>
            <button
              style={{ width: "16rem", cursor: "pointer" }}
              onClick={(e) => {
                window.open(emmision?.urlPoliza);
              }}
            >
              {lang.buttonText.download}
            </button>
          </div>
        </div>
      )}
            {
        ((emmision?.estadoEmision !== "EMITIDO" && canGoBack)&& (
          <div className="row justify-content-md-center py-5">
            <div className="col-md-4 py-1" style={{ textAlign: 'center' }}><button style={{ width: "16rem" }} onClick={() => history.push(`/pago?hashId=${hash}`)}>{lang.buttonText.goBack}</button></div>
          </div>
        ))
      }
    </div>
  );
};

export default FinalPage;
