import axios from "axios";

const url = process.env.REACT_APP_BASE_URL;
const seller = process.env.REACT_APP_SELLER_ORIGIN;


const instance = axios.create({
  baseURL: url,
  headers: {
    'origen': seller,
  },
});





export default instance;
