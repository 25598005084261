import { useEffect, useState } from 'react';
// import { selectErrorMessageActivities } from '../../Store/Activities/Selectors';
// import { selectErrorMessageEmmitQuote } from '../../Store/EmmitQuote/Selectors';
// import { selectErrorMessageFinalQuote } from '../../Store/FinalQuote/Selectors';
import { useAppSelector } from '../../Store/hooks';
import { selectErrorMessageLocalities } from '../../Store/Localities/Selectors';
import { selectPaymentErrorMessage } from '../../Store/Payment/Selectors';
import './ErrorModal.css'
import * as lang from "./lang.json"

/**
 * Main component.
 * @name ErrorModal
 * @returns {<ErrorModal />}
 */


const ErrorModal = () => {
  const [showModal, setShowModal] = useState(false);
  // const errorMessageActivities = useAppSelector(selectErrorMessageActivities);
  // const errorMessageEmmitQuote = useAppSelector(selectErrorMessageEmmitQuote);
  
  const errorMessageLocalities = useAppSelector(selectErrorMessageLocalities);

  useEffect(() => {
    //if (errorMessageActivities != '' ||  errorMessageEmmitQuote != '' ||  errorMessageFinalQuote != '' ||  errorMessageLocalities != ''){
    if (errorMessageLocalities != ''){
      setShowModal(true)
    }
  //},[errorMessageActivities, errorMessageEmmitQuote, errorMessageFinalQuote, errorMessageLocalities]);
  },[errorMessageLocalities]);
  
  return (
      <>
        {showModal && (
          <div className="service-errors-modal-container modal-bg">
            <div className="modal-content">
              <h1>{ lang.title }</h1>
              {/* <h3>{ errorMessageActivities }{ errorMessageEmmitQuote }{ errorMessageFinalQuote }{ errorMessageLocalities }</h3> */}
              <h3>{ errorMessageLocalities }</h3>
              <br />
              <button onClick={() => setShowModal(false)}>{ lang.button_text }</button>
            </div>
          </div>
        )}
      </>
  );
};

export default ErrorModal;