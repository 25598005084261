// React
import React from 'react';
import ReactDOM from 'react-dom';

// Libraries
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

// Pages
import { HomePage, PaymentPage, PlansPage, FinalPage } from './Pages';

// Parts
import { Template } from './Parts';

// Style
import './index.css';

// Store
import { store } from './Store/store';

// WebVitals
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Template>
          <Switch>
            <Route exact path='/'>
              <HomePage />
            </Route>
            <Route path='/planes'>
              <PlansPage />
            </Route>
            <Route path='/pago'>
              <PaymentPage />
            </Route>
            <Route path='/gracias'>
              <FinalPage />
            </Route>
          </Switch>
        </Template>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
