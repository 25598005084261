import { RootState } from "../store";
import { SecurityMeasuresValues } from "./Slice";

const getSecurityMeasuresCombo = (values: Array<SecurityMeasuresValues>) => {
    let options:Array<{ value:string, name: string}> = [];
    let securityMeasures = [...values];

    securityMeasures.forEach((condition) => {
        let option = { name: condition.label, value: condition.value };
        options.push(option);
    });
    return options;
}

export const selectSecurityMeasures = (state: RootState) => state.securityMeasures.securityMeasures;
export const selectSecurityMeasuresCombo = (state: RootState) => getSecurityMeasuresCombo(state.securityMeasures.securityMeasures);
export const selectErrorMessageSecurityMeasures = (state: RootState) => state.securityMeasures.errorMessage;