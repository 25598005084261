import { PaymentDataForm } from "@experta/commons-v2.payment-data/dist/utils";
import { InitiaLQuoteFormProps } from "../../Components/FormQuote/utils";
import { PropertyPaymentDataFormProps } from "../../Pages/Payment/utils";
import { PersonalDataFormProps } from "../../Parts/Forms/PersonalData/utils";
import { RootState } from "../store";
export const selectInitialFormData = (state: RootState): InitiaLQuoteFormProps => state.forms.initialForm as InitiaLQuoteFormProps;
export const selectPersonalFormData = (state: RootState): PersonalDataFormProps => state.forms.personalData as PersonalDataFormProps;
export const selectPropertyFormData = (state: RootState): PropertyPaymentDataFormProps => state.forms.propertyData as PropertyPaymentDataFormProps;
export const selectPoliticallyExposedFormData = (state: RootState) => state.forms.politicallyExposedData;
export const selectPaymentFormData = (state: RootState): PaymentDataForm => state.forms.paymentData as PaymentDataForm;
export const selectObligatedSubjectFormData = (state: RootState) => state.forms.obligatedSubjectData;
